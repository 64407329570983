import React, { useState, useRef, useEffect } from 'react';
import Papa from 'papaparse';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import 'bootstrap/dist/css/bootstrap.min.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './CsvToPdfUploader.css';

import Layout from "../layout";

const EditModal = ({ show, item, onSave, onCancel }) => {
  // Initialize editedItem with item if it's not null, otherwise initialize with an empty object
  const [editedItem, setEditedItem] = useState(item ?? {});

  useEffect(() => {
    // When the item prop changes, update the editedItem state, ensuring item is not null
    if (item) {
      setEditedItem(item);
    }
  }, [item]);

  if (!show) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedItem({ ...editedItem, [name]: value });
  };

  // Save the edits and close the modal
  const handleSave = () => {
    onSave(editedItem);
    onCancel(); // Close the modal
  };

  return (
    <div className="modal" style={{ display: 'block' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Item</h5>
            <button type="button" className="close btn btn-danger" onClick={onCancel}>
              &times;
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label>Listing Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="Listing name"
                  value={editedItem['Listing name'] || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>URL</label>
                <input
                  type="text"
                  className="form-control"
                  name="URL"
                  value={editedItem['URL'] || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Content Category</label>
                <input
                  type="text"
                  className="form-control"
                  name="Content Catogory" // This name must match the property in the data exactly
                  value={editedItem['Content Catogory'] || ''} // Make sure this matches too
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Content</label>
                <textarea
                  className="form-control"
                  name="Content"
                  value={editedItem['Content'] || ''}
                  onChange={handleChange}
                />
              </div>

              {/* Add inputs for other fields in a similar manner */}
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onCancel}>
              Cancel
            </button>
            <button type="button" className="btn btn-primary" onClick={handleSave}>
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  console.log(result);
  return result;
};

const CsvToPdf = () => {
  const printRef = useRef();
  const [isContentLoaded, setIsContentLoaded] = useState(false); // Initial state is false
  const [currentViewContent, setCurrentViewContent] = useState('');

  const [groupedData, setGroupedData] = useState({});
  const [editModalShow, setEditModalShow] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [viewContentModalShow, setViewContentModalShow] = useState(false);

  const handleViewMore = (item) => {
    console.log('Content being passed to modal:', item['Content']); // This should log the full content
    setCurrentViewContent(item['Content']);
    setViewContentModalShow(true);
  };

  const handleEdit = (item) => {
    // Ensure that item is not null before trying to edit
    if (item) {
      setCurrentItem(item);
      setEditModalShow(true);
    } else {
      // Handle the case when item is null or undefined
      console.error('Attempted to edit a null or undefined item.');
    }
  };

  const handleSaveEdit = (editedItem) => {
    // We're assuming that 'Content Catogory' is the correct property name
    const newCategory = editedItem['Content Catogory'].toLowerCase();
    const oldCategory = currentItem['Content Catogory'].toLowerCase();

    // If categories are the same, just update the item
    if (newCategory === oldCategory) {
      const newData = groupedData[oldCategory].map(item =>
        item.id === editedItem.id ? editedItem : item
      );
      setGroupedData(prev => ({ ...prev, [oldCategory]: newData }));
    } else {
      // If categories have changed, remove the item from the old category
      const oldCategoryData = groupedData[oldCategory].filter(item => item.id !== editedItem.id);
      // Add the item to the new category
      const newCategoryData = groupedData[newCategory]
        ? [...groupedData[newCategory], editedItem]
        : [editedItem];

      setGroupedData(prev => ({
        ...prev,
        [oldCategory]: oldCategoryData,
        [newCategory]: newCategoryData,
      }));
    }
    setEditModalShow(false);
  };


  const handleCancelEdit = () => {
    setEditModalShow(false);
  };

  // Delete item
  const deleteItem = (category, id) => {
    const newData = groupedData[category].filter(item => item.id !== id);
    setGroupedData(prev => ({ ...prev, [category]: newData }));
  };


  const loadContent = () => {
    // Simulate async content loading
    setTimeout(() => {
      setIsContentLoaded(true); // Set to true once content is loaded
    }, 1000); // Adjust timing based on your actual content loading logic
  };
  // Function to generate PDF from JSON - can be placed outside if it doesn't directly use component state
  const generatePdfFromJson = (categorizedData) => {
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });
  
    const marginLeft = 10;
    const marginTop = 10;
    const maxLineWidth = pdf.internal.pageSize.getWidth() - 2 * marginLeft;
    let yPos = marginTop;
  
    const calculateCardContentHeight = (item) => {
      let contentHeight = 20; // Start with a base height to account for fixed elements (title, URL, etc.)
      const lineSpacing = 5; // Line spacing between text lines
  
      // Estimate height for dynamic content
      if (item['Content']) {
        const contentWithoutHtml = item['Content'].replace(/<[^>]*>?/gm, ''); // Strip HTML tags
        const contentLines = pdf.splitTextToSize(contentWithoutHtml, maxLineWidth);
        contentHeight += (lineSpacing * contentLines.length); // Add height for the content
      }
  
      return contentHeight + 5; // Extra padding at the bottom of the card
    };
  
    Object.entries(categorizedData).forEach(([category, items]) => {
      // Check space for category header
      if (yPos + 10 > pdf.internal.pageSize.getHeight() - marginTop) {
        pdf.addPage();
        yPos = marginTop;
      }
  
      // Print category header
      pdf.setFontSize(14);
      pdf.setFont(undefined, 'bold');
      pdf.text(category, marginLeft, yPos);
      yPos += 7; // Space after the header
  
      items.forEach(item => {
        const cardHeight = calculateCardContentHeight(item);
  
        // Check if the card fits on the current page, else add a new page
        if (yPos + cardHeight > pdf.internal.pageSize.getHeight() - marginTop) {
          pdf.addPage();
          yPos = marginTop;
        }
  
        const startYPos = yPos; // Record starting Y position for the bounding box
  
        // Render the card content
        pdf.setFontSize(10);
        pdf.setFont(undefined, 'normal');
        pdf.setTextColor(0); // Default text color
  
        // Listing Name
        pdf.text(`Listing Name: ${item['Listing name']}`, marginLeft, yPos);
        yPos += 7;
  
        // URL as clickable link
        pdf.setTextColor(0, 0, 255); // Blue for link
        pdf.textWithLink('URL: Click Here', marginLeft, yPos, { url: item['URL'] });
        yPos += 7;
  
        // Content Category, if available
        if (item['Content Catogory']) {
          pdf.setTextColor(0); // Back to black
          pdf.text(`Content Category: ${item['Content Catogory']}`, marginLeft, yPos);
          yPos += 7;
        }
  
        // Content, stripping HTML
        if (item['Content']) {
          const contentWithoutHtml = item['Content'].replace(/<[^>]*>?/gm, ''); // Strip HTML tags
          const contentLines = pdf.splitTextToSize(contentWithoutHtml, maxLineWidth);
          contentLines.forEach(line => {
            pdf.text(line, marginLeft, yPos);
            yPos += 5; // Adjust line spacing as needed
          });
        }
  
        // Draw bounding box around the card
        pdf.setDrawColor(0); // Black color for bounding box
        pdf.setLineWidth(0.1); // Thin line
        pdf.rect(marginLeft - 1, startYPos - 6, maxLineWidth + 4, yPos - startYPos + 2); // Draw rectangle
  
        yPos += 5; // Space before next item
      });
      pdf.addPage();
      yPos = marginTop;
    });
  
    pdf.save('categorized-listing-with-full-cards.pdf');
  };
  
  
  

  // Handler for the button click
  const handleDownloadPdfClick = () => {
    console.log(groupedData)
    generatePdfFromJson(groupedData); // Pass the state data to the function
  };

  useEffect(() => {
    if (isContentLoaded) {
      // Call your handleDownloadPdf function here or enable the button to generate PDF
    }
  }, [isContentLoaded]);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const data = result.data;
          const grouped = data.reduce((acc, item, index) => {
            item.id = `item-${index}`; // Ensure each item has an id for the draggableId

            // Convert category to lowercase to group them case-insensitively
            const category = item['Content Catogory'].toLowerCase(); // Normalize to lowercase

            if (!acc[category]) acc[category] = [];
            acc[category].push(item);
            return acc;
          }, {});

          // Sort categories
          const sortedGroupedData = {};
          Object.keys(grouped).forEach(key => {
            let new_key = key;
            if (key == ""){
              new_key = "Uncategorized";
            }
            sortedGroupedData[new_key] = grouped[key];
          });

          setGroupedData(sortedGroupedData);
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  };

  const capitalizeCategory = (category) => {
    return category.replace(/\b\w/g, char => char.toUpperCase());
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    const category = result.source.droppableId;

    const items = reorder(groupedData[category], sourceIndex, destIndex);

    const newGroupedData = {
      ...groupedData,
      [category]: items
    };

    setGroupedData(newGroupedData);
  };
  const truncateText = (text, maxLength) => {
    const strippedString = text.replace(/(<([^>]+)>)/gi, ""); // Remove HTML
    if (strippedString.length <= maxLength) return strippedString;
    return strippedString.substr(0, strippedString.lastIndexOf(' ', maxLength)) + '...';
  };


  return (
    <Layout heading="CSV to PDF">
      <div>
        <button className="btn btn-primary pdf-button" onClick={handleDownloadPdfClick}>Generate PDF</button>
        <EditModal show={editModalShow} item={currentItem} onSave={handleSaveEdit} onCancel={handleCancelEdit} />
        <ContentViewModal show={viewContentModalShow} content={currentViewContent} onCancel={() => setViewContentModalShow(false)} />
        <div id="content-to-print">
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="container mt-5">
              <div className="mb-3">
                <input class="primary" type="file" className="form-control" onChange={handleUpload} />
              </div>
              {Object.keys(groupedData).map((category) => (
                <Droppable droppableId={category} key={category}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <h2>{capitalizeCategory(category)}</h2>
                      <div className="list-group">
                        {groupedData[category].map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style, // Make sure this is included
                                  backgroundColor: '#FFF8DC' // Your custom styles can follow
                                }}
                                className="list-group-item mb-3">
                                <p className="mb-1"><b>Listing name: </b>{item['Listing name']}</p>
                                <p className="mb-1"><b>URL:</b> <a href={item['URL']} target="_blank" rel="noopener noreferrer">{item['URL']}</a></p>
                                {item['Image Content'] && <p className="mb-1">Image Content: {item['Image Content']}</p>}
                                <p className="mb-1"><b>Content Category: </b>{item['Content Catogory']}</p>
                                {/* Render the HTML content */}
                                <p className="mb-1"><b>Content: </b></p>
                                <div className="content-preview" dangerouslySetInnerHTML={{ __html: item['Content'] || '' }}></div>

                                <button className="btn btn-sm btn-link" onClick={() => handleViewMore(item)}>View full Content</button>
                                <br></br>
                                <button onClick={() => handleEdit(item)} className="btn btn-sm btn-primary edit-btn">Edit</button>
                                <button onClick={() => deleteItem(category, item.id)} className="btn btn-sm btn-danger detele-btn">Delete</button>
                              </div>
                            )}
                          </Draggable>
                        ))}

                        <Droppable droppableId="droppableId">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {/* Draggable components here */}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>

                      </div>
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
          </DragDropContext>
        </div>
      </div>
    </Layout>
  );
};
const ContentViewModal = ({ show, content, onCancel }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal" style={{ display: 'block' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Content</h5>
            <button type="button" className="close btn btn-danger" onClick={onCancel}>
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default CsvToPdf;
