import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./App.css";

import LoginPage from "./components/auth/LoginPage";
import SignUpPage from "./components/auth/SignUp";

import HomePage from "./components/home/HomePage";

import ProjectsPage from "./components/projects";
import ProjectPage from "./components/projects/project";
import TemplatePage from "./components/projects/template";

import AdminPanel from "./components/admin/AdminPanel";
import ProjectFunctionality from "./components/admin/projectFunctionality";
import AdminFunctionality from "./components/admin/AdminFunctionality";
import TaskList from "./components/admin/AdminAllTaskPage";
import TaskTable from "./components/admin/AdminAllTaskTable";

import Search from "./components/search-bar/Search";

import ProtectedRoute from "./components/ProtectedRoute";
import CsvToPdfUploader from'./components/csv_to_pdf/CsvToPdfUploader';

import { UserProvider } from "./hooks/UserContext";


function NotFound() {
  return (
    <>
      <p>Not Found</p>
    </>
  );
}
function App() {
  // Initialize user state based on local storage
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  return (
    <UserProvider>
      <Router>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Route path="/login" component={LoginPage} />
          <Route path="/signup" component={SignUpPage} />
          <ProtectedRoute path="/homepage" component={HomePage} user={user} />
          
          <Route exact path="/projects" component={ProjectsPage} />
          <Route path="/projects/:projectId" component={ProjectPage} />
          <Route path="/templates/:templateId" component={TemplatePage} />

          {/* <ProtectedRoute path="/adminpanel" component={AdminPanel} user={user} /> */}
          <Route path="/adminpanel" component={AdminPanel} />
          <Route path="/admin_help/microtasks" component={AdminFunctionality} />
          <Route path="/admin_help/projects" component={ProjectFunctionality} />
          {/* <Route path="/admin_search_tasks" component={TaskList}/> */}
          <Route path="/admin_get_all_tasks" component={TaskTable}/>

          <Route path="/CsvToPdf" component={CsvToPdfUploader}/>
          <ProtectedRoute path="/search" component={Search} user={user} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </UserProvider>
  );
}

export default App;
