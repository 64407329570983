import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const user = JSON.parse(localStorage.getItem('user')); // You might want to do a more thorough check

  return (
    <Route {...rest} render={
      props => (
        user ?
          <Component {...props} />
        : <Redirect to='/login' />
      )
    } />
  );
};

export default ProtectedRoute;
