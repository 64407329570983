import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Include this only if Bootstrap is installed via npm
import Cookies from 'js-cookie';

import Layout from "../layout";


const TaskInstructions = () => {
    const [hasAdminAccess, setHasAdminAccess] = useState(false);

    useEffect(() => {
        // Retrieve the 'user' cookie
        const userCookie = Cookies.get('user');
        console.log(userCookie);
        if (userCookie) {
            // Parse the JSON data from the cookie
            const userDetails = JSON.parse(userCookie);
            
            // Check if the 'admin' field is true
            if (userDetails.admin === 1) {
                setHasAdminAccess(true);
            }
        }
    }, []);

    if (!hasAdminAccess) {
        return <p>Access Denied. No admin privileges.</p>;
    }
    return (
        <Layout heading="Microtask Operations">
            <hr />
            <h3>Adding a Microtask</h3>
            <h4>Procedure:</h4>
            <ul>
                <li>Navigate to task creation page</li>
                <li>Populate the following fields:
                    <ul>
                        <li>Microtask Number</li>
                        <li>Microtask Title</li>
                        <li>Microtask Description</li>
                        <li>Prompt</li>
                    </ul>
                </li>
                <li>Select "Create Task" to finalize the addition.</li>
            </ul>

            <h4>Scenarios:</h4>
            <p>Scenario 1:</p>
            <ul>
                <li>Existing Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.8, 1.0.1.5.9]</li>
                <li>Addition: Microtask 1.0.1.5.6</li>
                <li>Result: The new task is inserted at 1.0.1.5.6 without altering the sequence of other tasks.</li>
                <li>Updated Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.6, 1.0.1.5.8, 1.0.1.5.9]</li>
            </ul>
            <p>Scenario 2:</p>
            <ul>
                <li>Existing Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.8, 1.0.1.5.9]</li>
                <li>Addition: Microtask 1.0.1.5.3</li>
                <li>Result: The newly added task takes the place of the existing 1.0.1.5.3, prompting an increment in the subsequent tasks until a gap is encountered.</li>
                <li>Updated Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.5, 1.0.1.5.8, 1.0.1.5.9]</li>
            </ul>
            <hr />
            <h3>Deleting a Microtask</h3>
            <h4>Procedure:</h4>
            <ul>
                <li>Go to the specific microtask page that you wish to delete </li>
                <li>Click the "Delete" button and confirm by selecting "OK."</li>
            </ul>

            <h4>Scenarios:</h4>
            <p>Scenario 1:</p>
            <ul>
                <li>Existing Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.8, 1.0.1.5.9]</li>
                <li>Deletion: Microtask 1.0.1.5.4</li>
                <li>Result: Microtask 1.0.1.5.4 is removed. Remaining tasks are unaffected as there is no subsequent task.</li>
                <li>Updated Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.8, 1.0.1.5.9]</li>
            </ul>
            <p>Scenario 2:</p>
            <ul>
                <li>Existing Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.5, 1.0.1.5.8, 1.0.1.5.9]</li>
                <li>Deletion: Microtask 1.0.1.5.3</li>
                <li>Result: Microtask 1.0.1.5.3 is removed, and subsequent tasks are decremented to fill the gap.</li>
                <li>Updated Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.8, 1.0.1.5.9]</li>
            </ul>
            <hr />
            <h3>Editing a Microtask Number</h3>
            <h4>Procedure:</h4>
            <ul>
                <li>Access the desired microtask's edit page that you wish to edit</li>
                <li>Click the "Edit Task Number" button.</li>
                <li>Enter the new task number.</li>
                <li>Click "Save" to apply changes.</li>
            </ul>

            <h4>Scenarios:</h4>
            <p>Scenario 1:</p>
            <ul>
                <li>Existing Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.8, 1.0.1.5.9]</li>
                <li>Edit: Change 1.0.1.5.4 to 1.0.1.5.5</li>
                <li>Result: The number of the microtask is changed from 1.0.1.5.4 to 1.0.1.5.5. There is no incrementation as no existing task occupies the new number.</li>
                <li>Updated Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.5, 1.0.1.5.8, 1.0.1.5.9]</li>
            </ul>
            <p>Scenario 2:</p>
            <ul>
                <li>Existing Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.5, 1.0.1.5.8, 1.0.1.5.9]</li>
                <li>Edit: Change 1.0.1.5.3 to 1.0.1.5.8</li>
                <li>Result: The microtask 1.0.1.5.3 is removed and added to 1.0.1.5.8. Existing 1.0.1.5.8 is incremented to 1.0.1.5.9, and so on.</li>
                <li>Updated Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.8, 1.0.1.5.9, 1.0.1.5.10]</li>
            </ul>
        </Layout>
    );
};

export default TaskInstructions;
