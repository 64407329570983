import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import Notify from "./notification";
import Head from "./head";
import Sidebar from "./sidebar";

export default function Layout({ heading="", children="" }) {
  const [collapsed, setCollapsed] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const menuPermissions = user["user_menu"];

  useEffect(() => {
    if (!menuPermissions || menuPermissions.length === 0) {
      setShowAlert(true);
    }
  }, [menuPermissions]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  }

  return (
    <div className="layout-div ">
      {
        showAlert && <Notify message="You don't have any permissions yet. Please contact administator." />
      }
      <Sidebar collapsed={collapsed} handleCollapse={handleCollapse} />
      <div className="layout-main-container">
        <Head heading={heading} collapsed={collapsed} handleCollapse={handleCollapse} />
        <div className="layout-content">
          {children}
        </div>
      </div>
    </div>
  );
}
