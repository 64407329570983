import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Cookies from 'js-cookie'; 
import { useHistory } from 'react-router-dom'; 

import Layout from "../layout";

const AdminPanel = () => {
  const [menuData, setMenuData] = useState([]);
  const [selected, setSelected] = useState({});
  const [email, setEmail] = useState('');
  const [selectedItems, setSelectedItems] = useState({});
  const [emailList, setEmailList] = useState([]);
  const [filteredEmailList, setFilteredEmailList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [hasAdminAccess, setHasAdminAccess] = useState(false);
  const history = useHistory();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setShowDropdown(true);
  };
  // Step 2: Create 'toggleSelectAll' function
  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const newSelected = { ...selected };
    
    const markItems = (items) => {
      Object.keys(items).forEach((key) => {
        newSelected[key] = newSelectAll;
        if (items[key].children) {
          items[key].children.forEach((child) => markItems(child));
        }
      });
    };
    // Loop through all root-level menus
    menuData.forEach((menu) => markItems(menu));

    setSelected(newSelected);
  };  
  useEffect(() => {
    fetch("https://biotasks.com/api/get_user_email")
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setEmailList(data.data.emails);
        }
      });
  }, []);
  useEffect(() => {
    setFilteredEmailList(
      emailList
        .filter((e) => e.toLowerCase().startsWith(email.toLowerCase()))
        .sort()
    );
  }, [email, emailList]);
  
  const generatePreviewData = (menuItems, depth = 0) => {
    let preview = [];
  
    menuItems.forEach(itemObj => {
      const key = Object.keys(itemObj)[0];
      const item = itemObj[key];
  
      if (selected[key]) {
        const listItem = {
          type: 'item',
          value: item.text,
          depth,
          children: item.children && item.children.length > 0
                   ? generatePreviewData(item.children, depth + 1)
                   : []
        };
        preview.push(listItem);
      }
    });
  
    return preview;
  };
  
  const renderPreviewList = (items, depth = 0) => {
    if (!items || items.length === 0) {
      return null;
    }
  
    return (
      <ul style={{ paddingLeft: depth === 0 ? '0' : '20px' }}>
        {items.map((item, index) => (
          <li key={index}>
            {item.value}
            {renderPreviewList(item.children, depth + 1)}
          </li>
        ))}
      </ul>
    );
  };
  
  
  const RenderHtml = ({ item, level }) => {
    const paddingLeft = level * 20; // Adjust padding as needed for indentation
    return (
      <li style={{ paddingLeft: `${paddingLeft}px` }}>
        {item}
      </li>
    );
  };
  
  const handleEmailSelect = async (selectedEmail) => {
    setEmail(selectedEmail);
    setShowDropdown(false);
  
    // Reset the selected state completely
    setSelected({}); // This line clears all previous selections
  
    const payload = { email: selectedEmail };
  
    try {
      const response = await fetch(`https://biotasks.com/api/get_permission_for_selected_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      if (data.permissions) {
        // Wait for the state to reset before updating it with new permissions
        setSelected(() => {
          const newSelected = {};
          updateSelectedCheckboxes(data.permissions, newSelected);
          return newSelected;
        });
      } else if (data.error) {
        console.error('Error:', data.error);
        alert(data.error); // Display the error message from the API
      }
    } catch (error) {
      console.error('Error fetching user permissions:', error);
      alert(`An error occurred: ${error.message}`);
    }
  };
  
  const updateSelectedCheckboxes = (permissions, newSelected) => {
    const markItemsAsSelected = (items) => {
      items.forEach(item => {
        const key = Object.keys(item)[0];
        newSelected[key] = true;
  
        if (item[key].children) {
          markItemsAsSelected(item[key].children, newSelected);
        }
      });
    };
    markItemsAsSelected(permissions);
  };
  
   
  const handleCheckboxChange = (key, item, parentKeys = []) => {
    const newSelected = { ...selected };
    newSelected[key] = !selected[key];
    if (!selected[key]) {
      parentKeys.forEach(parentKey => {
        newSelected[parentKey] = true;
      });
    }
    setSelected(newSelected);
    setSelectedItems(newSelected);
  };

  const renderTree = (menu, parentKeys = []) => {
    return Object.keys(menu).map((key, index) => {
      const item = menu[key];
      const newParentKeys = [...parentKeys, key];
      return (
        <div key={index} className="ms-3">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={!!selected[key]}
              onChange={() => handleCheckboxChange(key, item, parentKeys)}
            />
            <label className="form-check-label">{item.text}</label>
          </div>
          {item.children && (
            <div className="ms-3">
              {item.children.map((child) => renderTree(child, newParentKeys))}
            </div>
          )}
        </div>
      );
    });
  };
  const sendSelectedData = () => {
    const preview = generatePreviewData(menuData);
    setPreviewData(preview);
    setShowModal(true); // Assuming showModal is the state controlling the visibility of your preview modal
  };
  
  
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('https://biotasks.com/api/menu_list');
      const data = await response.json();
      if (data.status === 'success') {
        setMenuData(data.data.menu);
      }
    };
    fetchData();
  }, []);
 
  // const handleEmailSelect = (e) => {
  //   setEmail(e);
  //   console.log("Email after set:", e); // Debug line
  //   setShowDropdown(false);
  // };
  useEffect(() => {
    // Retrieve the 'user' cookie
    const userCookie = Cookies.get('user');
    console.log(userCookie);
    if (userCookie) {
      // Parse the JSON data from the cookie
      const userDetails = JSON.parse(userCookie);
      
      // Check if the 'admin' field is true
      if (userDetails.admin === 1) {
          setHasAdminAccess(true);
      }
    }
  }, []);

  if (!hasAdminAccess) {
    return <p>Access Denied. No admin privileges.</p>;
  }

  const handleBack = () => {
    setShowModal(false);
  };
  
  const handleSubmit = async () => {
    setShowModal(false); // Close the modal first
  
    // Prepare the data to be sent - modify as per your API requirements
    const dataToSend = {
      email: email,
      selectedItemsHierarchy: selected, // Assuming 'selected' holds the required data
    };
    console.log(dataToSend);
  
    try {
      const response = await fetch('https://biotasks.com/api/update_user_permissions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies
        body: JSON.stringify(dataToSend),
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.status === 'success') {
          alert('Permission granted');
  
          // Reset selected checkboxes
          setSelected({});
          setSelectAll(false); // Also reset the 'Select All' checkbox if you have one
          setShowModal(false); // Close the modal
        } else {
          // Handle any other status accordingly
          alert('Failed to grant permission');
        }
      } else {
        console.error('HTTP Error:', response.status);
        alert('Failed to update permissions');
      }
    } catch (error) {
      // console.error('Error:', error)
      alert('An error occurred while updating permissions');
    }
  };
  
  return (
    <Layout heading="Admin Panel">
      
      <div className="mb-3">
        <div className="input-group">
          <input 
            type="text" 
            className="form-control"
            placeholder="Enter Email ID" 
            value={email}
            onChange={handleEmailChange}
            onFocus={() => setShowDropdown(true)} 
          />
          <button 
            className="btn btn-outline-secondary dropdown-toggle"
            type="button" 
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <i className={`bi ${showDropdown ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
          </button>
        </div>
        <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          checked={selectAll}
          onChange={toggleSelectAll}
        />
        <label className="form-check-label">Select All</label>
      </div>
      <div className="dropdown-menu" style={{ display: showDropdown ? 'block' : 'none' }}>
        {filteredEmailList.map((e, index) => (
          <a 
            key={index} 
            className="dropdown-item" 
            onClick={() => handleEmailSelect(e)} // Updated to call handleEmailSelect
          >
            {e}
          </a>
        ))}
      </div>
      </div>
      {
        menuData.map((menu, index) => (
          <div key={index}>{renderTree(menu)}</div>
        ))
      }
      <button className="btn btn-primary mt-3" onClick={sendSelectedData}>Submit</button>
      {/* Bootstrap Modal for Preview */}
      <div className={`modal fade ${showModal ? 'show' : ''}`} id="previewModal" tabIndex="-1" aria-labelledby="previewModalLabel" aria-hidden={!showModal} style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="previewModalLabel">Selected Items Preview</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <ul className="preview-list">
                {renderPreviewList(previewData)}
              </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
              <button type="button" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default AdminPanel;