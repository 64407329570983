import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Include this only if Bootstrap is installed via npm
import Cookies from 'js-cookie';

import Layout from "../layout";


const ProjectFunctionality = () => {
    const [hasAdminAccess, setHasAdminAccess] = useState(false);

    useEffect(() => {
        // Retrieve the 'user' cookie
        const userCookie = Cookies.get('user');
        console.log(userCookie);
        if (userCookie) {
            // Parse the JSON data from the cookie
            const userDetails = JSON.parse(userCookie);
            
            // Check if the 'admin' field is true
            if (userDetails.admin === 1) {
                setHasAdminAccess(true);
            }
        }
    }, []);

    if (!hasAdminAccess) {
        return <p>Access Denied. No admin privileges.</p>;
    }
    return (
        <Layout heading="Projects Operations">
            <hr />
            <h3 id="adding">Adding Microtasks and Connectors:</h3>
            <h4>Procedure:</h4>
            <ul>
                <li>Navigate to "See Connectors/Widgets"</li>
                <li>Steps to add new task:
                    <ul>
                        <li>Click on "Add New Task"</li>
                        <li>Select Connector or Microtask</li>
                        <li>Input the task number</li>
                        <li>Click on "Preview"</li>
                        <li>Scroll down and click on "Add to template"</li>
                    </ul>
                </li>
            </ul>

            <h4>Scenarios:</h4>
            <p>Scenario 1:</p>
            <ul>
                <li>Existing Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.8, 1.0.1.5.9]</li>
                <li>Addition: Microtask 1.0.1.5.6</li>
                <li>Result: The new task is inserted at 1.0.1.5.6 without altering the sequence of other tasks.</li>
                <li>Updated Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.6, 1.0.1.5.8, 1.0.1.5.9]</li>
            </ul>
            <hr />

            <h3>Removing Microtasks and Connectors:</h3>
            <h4>Procedure:</h4>
            <ul>
                <li>Navigate to "See Connectors/Widgets"</li>
                <li>Steps to remove a task or a connector:
                    <ul>
                        <li>Click on the task or connector that you would like to remove</li>
                        <li>Scroll down and click on "Delete"</li>
                    </ul>
                </li>
            </ul>

            <h4>Scenarios:</h4>
            <p>Scenario 1:</p>
            <ul>
                <li>Existing Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.8, 1.0.1.5.9]</li>
                <li>Deletion: Microtask 1.0.1.5.4</li>
                <li>Result: Microtask 1.0.1.5.4 is removed. Remaining tasks are unaffected as there is no subsequent task.</li>
                <li>Updated Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.8, 1.0.1.5.9]</li>
            </ul>
            <p>Scenario 2:</p>
            <ul>
                <li>Existing Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.5, 1.0.1.5.8, 1.0.1.5.9]</li>
                <li>Deletion: Microtask 1.0.1.5.3</li>
                <li>Result: Microtask 1.0.1.5.3 is removed, and subsequent tasks are decremented to fill the gap.</li>
                <li>Updated Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.8, 1.0.1.5.9]</li>
            </ul>
            <hr />

            <h3 id="editing">Editing a Microtask or Connector</h3>
            <h4>Procedure:</h4>
            <ul>
                <li>To edit the title, position and description:</li>
                <ul>
                    <li>Navigate to "See Connectors/Widges"</li>
                    <li>Click on the task or connector that you would like to edit</li>
                    <li>Click on Edit (pencil icon)</li>
                    <li>The textarea below will enable and you can edit the text</li>
                    <li>After making the changes, scroll down to the end and click on "save/update"</li>
                </ul>
                <li>To edit the inputs, and prompt:</li>
                <ul>
                    <li>Navigate to "See All Project Data"</li>
                    <li>Click on the task or connector that you would like to edit</li>
                    <li>Click on Edit (pencil icon)</li>
                    <li>The textarea below will enable and you can edit the text</li>
                    <li>After making the changes, scroll down to the end and click on "save/update"</li>
                </ul>
            </ul>

            <h4>Scenarios:</h4>
            <p>Scenario 1:</p>
            <ul>
                <li>Existing Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.8, 1.0.1.5.9]</li>
                <li>Edit: Change 1.0.1.5.4</li>
                <li>Result: The microtask/connector has been updated</li>
                <li>Updated Microtasks: [1.0.1.5.2, 1.0.1.5.3, 1.0.1.5.4, 1.0.1.5.8, 1.0.1.5.9]</li>
            </ul>
            <hr />

            <h3 id="connect">Connect microtask</h3>
            <h4>Procedure:</h4>
            <ul>
                <li>Navigate to "See Connectors/Widges"</li>
                <li>Add a connector. <a href="#adding">learn how to add a connector</a></li>
                <li>Click on the newly added connector to edit it. <a href="#editing">learn how to edit a connector</a></li>
                <li>In the "input from" field add all the, comma seperated, microtask number that you would like to get input from</li>
                <li>In the "output to" field add all the, comma seperated, microtask number that you would like to send the output to</li>
                <li>Scroll down to the end and click on "save/update"</li>
            </ul>

            <h4>Scenarios:</h4>
            <p>Scenario 1:</p>
            <ul>
                <li>Existing Microtasks: [1.0.1.5.2, 1.0.1.5.4, connector, 1.0.1.5.8]</li>
                <li>Edit: adding 1.0.1.5.2 and 1.0.1.5.4 in connector's "input from" and 1.0.1.5.8 in the "output to"</li>
                <li>Result: The microtask/connector has been connected</li>
                <li>Updated Microtasks: 1.0.1.5.2 & 1.0.1.5.4 connected to connector and 1.0.1.5.8 will be supplied the output from both of them</li>
            </ul>
            <hr />

            <h3>Create a pipeline</h3>
            <h4>Procedure:</h4>
            <ul>
                <li>Navigate to "See Connectors/Widges"</li>
                <li>Connect the tasks <a href="#connect">Learn from here</a></li>
                <li>In the connector's "Microtask Completion Activation Trigger" field add the microtask number that will be observed. When the microtask specified is completed the next task will execute automatically.</li>
                <li>In the same connector as above, check the field labeled as "Automatically change status". This will automatically change the status of task that just completed it's execution.</li>
            </ul>

            <h4>Scenarios:</h4>
            <p>Scenario 1:</p>
            <ul>
                <li>Existing Microtasks: [1.0.1.5.4, connector, 1.0.1.5.8]</li>
                <li>adding 1.0.1.5.4 in connector's "Microtask Completion Activation Trigger" and checked the automatic status change.</li>
                <li>Result: The pipeline is complete</li>
                <li>Execution: 1.0.1.5.4 -&gt; connector(takes the output of previous task and feeds it to the next one) -&gt; 1.0.1.5.8 </li>
            </ul>
            <hr />

            <h3>Save a template</h3>
            <h4>Procedure:</h4>
            <ul>
                <li>Once you have finished editing, type a name in top right corner input box labeled as "template name" and click on "save"</li>
            </ul>

            <h4>Scenarios:</h4>
            <p>Scenario 1:</p>
            <ul>
                <li>Existing Microtasks & Connectors in template: [1.0.1.5.2, 1.0.1.5.4, connector, 1.0.1.5.8]</li>
                <li>Type a name: "new" and click on "save"</li>
                <li>Result: The new template is added</li>
            </ul>
            <hr />

            <h3 id="switchtemplate">Switch between a template</h3>
            <h4>Procedure:</h4>
            <ul>
                <li>Select a template form dropdown, labeled as "Switch template".</li>
            </ul>

            <h4>Scenarios:</h4>
            <p>Scenario 1:</p>
            <ul>
                <li>Current template: template1</li>
                <li>after switching: tempalte2</li>
            </ul>
            <hr />

            <h3>Load a template</h3>
            <h4>Procedure:</h4>
            <ul>
                <li>From the dropdown labeled as "load template", select the template you would like to load</li>
                <li>Click on "load" and the template will be loaded</li>
                <li>Now you can switch inbetween templates. <a href="#switchtemplate">Learn how to switch template</a></li>
            </ul>

            <h4>Scenarios:</h4>
            <p>Scenario 1:</p>
            <ul>
                <li>Existing templates: [template1, template2]</li>
                <li>After loading template3</li>
                <li>Result: The new template is added [template1, template2, template3]</li>
            </ul>
            <hr />

            <h3>Delete/Remove a template from the project</h3>
            <h4>Procedure:</h4>
            <ul>
                <li>Click on "Delete Template" and the template will be removed</li>
            </ul>

            <h4>Scenarios:</h4>
            <p>Scenario 1:</p>
            <ul>
                <li>Existing templates: [template1, template2]</li>
                <li>After deleting template2</li>
                <li>Result: The new template list is [template1]</li>
            </ul>
            <hr />
        </Layout>
    );
};

export default ProjectFunctionality;
