import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {setUser} from '../../hooks/UserContext';
import { useUser } from '../../hooks/UserContext';
import Cookies from 'js-cookie';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const history = useHistory();
    const { setUser } = useUser();
    const handleLogin = () => {
        const payload = {
            email: email,
            password: password
        };
    
        fetch("https://biotasks.com/api/login", {
        // fetch("http://localhost:5001/api/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === "success") {
                // After successful login
                const user = data; // Replace with your actual user data
                setUser(user);
                const usercookie = {
                    email: user['email'],
                    admin: user['admin']
                };
              
                // console.log(usercookie);
                Cookies.set('user', JSON.stringify(usercookie), { expires: 7 });

                // if (Cookies.get('user')) {
                //     console.log('Test cookie set:', Cookies.get('user'));
                // } else {
                //     console.log('Test cookie not set');
                // }

                window.location.assign('/homepage');
    
            } else {
                alert("Invalid username or password");
            }
        })
        .catch(error => {
            console.error("There was a problem with the login request", error);
        });
    };
    
    const isCookieSet = (cookieName) => {
        const cookie = Cookies.get(cookieName);
        return cookie !== undefined;
    };
    const goToSignUp = () => {
        history.push('/signup');
        window.location.reload(); 
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="card p-4 shadow-lg border-0" style={{ width: '20rem' }}>
                <div className="pt-4 pb-4">
                    <h2 className="text-center mb-4">Login</h2>
                    <div className="mb-3">
                        <input 
                            type="email" 
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <input 
                            type="password" 
                            className="form-control"
                            placeholder="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                    <button className="btn btn-dark w-100" onClick={handleLogin}>Login</button>
                    <br></br><br></br>
                    <div style={{ textAlign: "center" }}>
                        <span style={{cursor: "pointer", color: "blue", textDecoration: "underline"}} onClick={goToSignUp}>Go to Sign Up</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LoginPage;
