
import { useState, useEffect } from 'react';

const useMaxPos = () => {
    return (data) => {
        let maxPosition = 0;

        // Check microtasks
        if (data.microtasks) {
            data.microtasks.children.forEach(microtask => {
                if (microtask.position > maxPosition) {
                    maxPosition = microtask.position;
                }
            });
        }
        // Check connectors
        if (data.connectors) {
            data.connectors.connectors.children.forEach(connector => {
                if (connector.position > maxPosition) {
                    maxPosition = connector.position;
                }
            });
        }

        return maxPosition;
    }
};

export default useMaxPos;
