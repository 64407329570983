import React, { useState, useEffect, useRef } from "react";
import { Button, Nav } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Cookies from 'js-cookie';


import "bootstrap/dist/css/bootstrap.min.css";

import { getTemplate } from "../../../libs/templates";

import useTaskParser from "../../../hooks/useTemplateParser";

import Layout from "../../layout";
import Modal from "../../modals/modal1";

import TemplateNavs from "./navs";
import Widget from "../widget";
import Card from "../cards";

import "./style.css";




// 0: all projects, 1: connectors/widgets, 2: wireframe
export default function TemplatePage() {
  const [view, setView] = useState(1); //1: grid, 2: half, 3: full
  const [screen, setScreen] = useState(0);
  const [modalOptions, setModalOptions] = useState({
    open: false,
    heading: "",
    content: ""
  });
  const [templateJSON, setTemplatJSON] = useState(null);

  const { templateId } = useParams();

  useEffect(() => {
    getTemplate(templateId).then(async (data) => {
      setTemplatJSON(data);
    });
  }, []);


  let sortedData = useTaskParser(templateJSON);
  
  if (!templateJSON) return (<></>);

  return (
    <Layout heading={templateJSON.name}>
      <div className="template-div">
        {
          modalOptions.open &&
          <Modal
            setModalOptions={setModalOptions} {...modalOptions}
          />
        }
        {/**/}
        <TemplateNavs
            screen={screen} setScreen={setScreen} templateJSON={templateJSON}
            setModalOptions={setModalOptions} setView={setView}
        />
        <div className="content-container-div">
          {
            sortedData.map((z, i) => z && 
              view == 1 ? <Widget key={i} data={{...z}} setModalOptions={setModalOptions} preview={true} /> :
              view == 2 ? <Card key={i} data={{...z}} setModalOptions={setModalOptions} preview={true} view={view} /> :
              <Card key={i} data={{...z}} setModalOptions={setModalOptions} preview={true} />
            )
          }
          {/* <Widget icon={<BsListTask />} data={{...tempData}}
            setModalOptions={setModalOptions} copyToClipboard={copyToClipboard} />
          <Widget icon={<BsPlugin />} data={{...tempDataC, template: "connector"}}
            setModalOptions={setModalOptions} copyToClipboard={copyToClipboard} />
          <Widget icon={<BsRobot />} data={{...tempDataGPT}}
            setModalOptions={setModalOptions} copyToClipboard={copyToClipboard} /> */}
        </div>
      </div>
    </Layout>
  );
}
