// Function to generate a random ID
function generateID() {
    return Math.random().toString(36).substring(2);
}

// Hook function to generate unique IDs
function useIDGenerator() {
    const generatedIDs = new Set();

    // Generate a unique ID
    function generateUniqueID() {
        let id;
        do {
            id = generateID();
        } while (generatedIDs.has(id)); // Ensure the ID is unique
        generatedIDs.add(id);
        return id;
    }

    return generateUniqueID;
}

export {useIDGenerator};