const endpointBase = process.env.REACT_APP_HOST;

const getMicrotask = async (taskId = null) => {
    let returnJson = null;
    try {
        if (!taskId) throw "no task id";

        let res = await fetch(endpointBase+"/api/pull_microtask/"+taskId);
        let data = await res.json();

        if (data.template.includes("python")) throw "python task";
        returnJson = data;
    }catch(err) {
        console.log(err);
    }finally {
        return returnJson;
    }
};

const updateTaskPosition = async (postData=null) => {
    let returnJson = null;
    try {
        if (!postData) throw "no task id";
        
        let res = await fetch(endpointBase+"/api/modify_task_position", {
            method: "post",
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(postData)
        });
        let data = await res.json();

        returnJson = data;
    }catch(err) {
        console.log(err);
    }finally {
        return returnJson;
    }
};

export { getMicrotask, updateTaskPosition };