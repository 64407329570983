import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie'; 
function TaskList() {
  const [tasks, setTasks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasAdminAccess, setHasAdminAccess] = useState(false);

  useEffect(() => {
    let isComponentMounted = true;
    const userCookie = Cookies.get('user');
    if (userCookie) {
      const userDetails = JSON.parse(userCookie);
      if (userDetails.admin === 1) {
        setHasAdminAccess(true);
        axios.get('https://biotasks.com/api/get_all_microtasks')
          .then(response => {
            if (isComponentMounted) {
              setTasks(response.data);
            }
          })
          .catch(error => {
            console.error('There was an error fetching the tasks!', error);
          });
      }
    }

    return () => {
      isComponentMounted = false;
    };
  }, []);

  if (!hasAdminAccess) {
    return <p>Access Denied. No admin privileges.</p>;
  }
  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <input 
            className="form-control"
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by microtask..."
          />
        </div>
      </div>
      <div className="row mt-3">
        {tasks.filter(task => 
          task.microtask.toLowerCase().includes(searchTerm.toLowerCase())
        ).map(task => (
          <div key={task._id} className="col-12 mb-3">
            <div className="card">
              <div className="card-body">
                {Object.entries(task).map(([key, value]) => {
                  if (key === '_id') return null; // Exclude the _id field
                  return (
                    <p key={key} className="card-text">
                      <strong>{key}:</strong> {Array.isArray(value) ? value.join(', ') : value}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
              }  

export default TaskList;
