import React, { useState, useEffect } from "react";
import { Button, Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';

import "bootstrap/dist/css/bootstrap.min.css";

import Layout from "../layout";

import ProjectsFilter from "./projects_filters";
import ProjectsTable from "./projects_table";

import "./style.css";
//import "../TopBar.css";
// import userData from '../navData.json';



export default function ProjectsPage() {
  const [activeProjectTypes, setActiveProjectTypes] = useState({"Project": true, "Template": true});
  const [activeProjectStatus, setActiveProjectStatus] = useState({"0": true, "1": true});

  return (
    <Layout heading="Projects">
      <div className="projects-div">
        <ProjectsFilter
          setActiveProjectTypes={setActiveProjectTypes}
          setActiveProjectStatus={setActiveProjectStatus}
        />
        <br />
        <ProjectsTable
          activeProjectTypes={activeProjectTypes}
          activeProjectStatus={activeProjectStatus}
        />
      </div>
    </Layout>
  );
}
