import React, { Component, useRef }  from 'react';
import { useHistory } from 'react-router'

import { getAllProjects, createProject } from "../../libs/projects";

import { useIDGenerator } from "../../hooks/useIdGen";


export default function ProjectsFilter({ setActiveProjectTypes=()=>{}, setActiveProjectStatus=()=>{} }) {
  const history = useHistory();
  const projectNameRef = useRef(null);

  const generateUniqueID = useIDGenerator();

  const handleChange = (e) => {
    setActiveProjectTypes(state => ({
      ...state,
      [e.target.value]: e.target.checked
    }))
  }

  const handleStatusChange = (e) => {
    setActiveProjectStatus(state => {
      if (e.target.value > 1) {
        return {"0": true, "1": true};
      }

      if (e.target.value == 1) {
        return {"1": true};
      }
      if (e.target.value == 0) {
        return {"0": true};
      }
    })
  }

  const handleAddClick = async () => {
    if (!projectNameRef.current.value) return 0;

    let allPs = await getAllProjects();
    if( !(allPs.find(z => z.name == projectNameRef.current.value)) ) {
      let data = await createProject(generateUniqueID(), projectNameRef.current.value);
      history.go(0);
    }
  }

  return (
    <div className="d-flex flex-wrap align-items-center gap-3">
      <section className="d-flex align-items-center border px-3 py-2 project-filter-secs">
        <label htmlFor="show-projects-check" className="">Show Projects</label>
        <input id="show-projects-check" type="checkbox" value={"Project"} onChange={handleChange} defaultChecked />
      </section>
      <section className="d-flex align-items-center border px-3 py-2 project-filter-secs">
        <label htmlFor="show-temlplates-check" className="">Show Templates</label>
        <input id="show-temlplates-check" type="checkbox" value={"Template"} onChange={handleChange} defaultChecked />
      </section>
      <section className="d-flex align-items-center border  project-filter-secs">
        <select className="px-3 py-2 border-0" onChange={handleStatusChange}>
          <option value="2">Show All Status</option>
          <option value="1">Show Completed Setups</option>
          <option value="0">Show Not Completed Setups</option>
        </select>
      </section>

      <section className='d-flex align-items-center justify-content-end gap-3 ms-auto'>
        <input type="text" placeholder='project name' className='border-bottom px-2 w-50' ref={projectNameRef} />
        <button className="btn btn-primary" onClick={handleAddClick}>Add New Project</button>
      </section>
    </div>
  );
}