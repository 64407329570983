import React, { useEffect, useRef, useState } from "react";

import { BsPlugin, BsRobot, BsListTask, BsNodePlus } from "react-icons/bs";
import * as d3 from "d3";


import ManualTaskContent from "../manualtask_content";
import AITaskContent from "../aitask_content";
import ConnectorContent from "../connector_content";


export default function Canvas({
  width = 640,
  height = 400,
  marginTop = 20,
  marginRight = 20,
  marginBottom = 20,
  marginLeft = 20,

  data=[],
  preview=false, screen=0, modalOptions={}, modalKey=1, execution, connectors=[],
  editTaskJSON=()=>{}, deleteTaskJSON=()=>{}, setModalOptions=()=>{}, runAITask=()=>{}, setModalKey=()=>{}, editVector=()=>{}
}) {
  const svgRef = useRef();
  const dragged = useRef(false);
  //console.log(execution.positions)

  const [trnasformValue, setTransformValue] = useState({x: 1, y: 1, k: 0.5});
  const [changes, setChanges] = useState([]);
  const [intervalId, setIntervalId] = useState(null);

  const handleDelClick = async (type, taskid, position) => {
    await deleteTaskJSON(type, taskid, position);
  }
  const handleSaveClick = async (type, taskid, position, callback) => {
    try {
      //console.log(changes)
      let temp = [];
      changes.forEach(z => {
        if (!(z.valueRef && z.valueRef.current)) {return 0;}
        if (z.key == "taskInput") {
          if (z.valueRef.current.value.length) {
            temp.push({ key: z.key, value: z.valueRef.current.value.split("\n") });
          }else {
            temp.push({ key: z.key, value: "" });
          }
        }else if(z.key == "input_from" || z.key == "input_to" || z.key == "activation_trigger") {
          temp.push({ key: z.key, value: z.valueRef.current.value.split(".").join("_") });
        }else if(z.key == "combineLine") {
          temp.push({ key: z.key, value: z.valueRef.current.checked });
        }else {
          temp.push({ key: z.key, value: z.valueRef.current.value });
        }
      });
      await editTaskJSON(type, taskid, position, temp);
      
      // reset
      setChanges([]);

      if (callback) callback();
    }catch(err) {
      console.log(err);
    }
  }

  const handleEditClick = (key, elementRef, disable=true) => {
    try {
      disable && (elementRef.current.disabled = !elementRef.current.disabled);

      // add all the fields keys and values, that needs to updated
      if (!changes.find(z => z.key == key)) {
        setChanges(state => {
          if (!state.find(z => z.key == key)) {
            state.push({ key: key, valueRef: elementRef });
          }

          return state;
        });
      }
    }catch(err) {
      console.log(err)
    }
  }
  
  const handleWidgetClick = (widgetData, removeChange=true) => {
    removeChange && setChanges(state => {state.length = 0; return state});

    //console.log(widgetData)

    let tempCon;
    if (widgetData.connector_id) {
      tempCon = <ConnectorContent {...widgetData} handleDelClick={handleDelClick} preview={preview} screen={screen}
        handleSaveClick={handleSaveClick} handleEditClick={handleEditClick} executionState={ execution.positions.indexOf(widgetData.position) >= 0 } />
    }else if (widgetData.template.includes("manual")) {
      tempCon = <ManualTaskContent {...widgetData} handleDelClick={handleDelClick} preview={preview} screen={screen}
      handleSaveClick={handleSaveClick} handleEditClick={handleEditClick} executionState={ execution.positions.indexOf(widgetData.position) >= 0 } />;
    }else if (widgetData.template.includes("chatGPT")) {
      tempCon = <AITaskContent {...widgetData} handleDelClick={handleDelClick} preview={preview} screen={screen}
      handleSaveClick={handleSaveClick} handleEditClick={handleEditClick} runAITask={runAITask} executionState={ execution.positions.indexOf(widgetData.position) >= 0 } />
    }
    
    setModalKey(Math.random()*9999999);
    tempCon && setModalOptions((state) => ({
      open: true,
      heading: widgetData.microtask ? widgetData.microtask.split("_").join(".") : "connector",
      content: tempCon,
      data: widgetData,
    }));
  }


  useEffect(() => {
    if (modalOptions.open) {
      let newData = data.find(z => z.position == modalOptions.data.position);
      handleWidgetClick(newData, false); // not updating the change state so it's not flooding useEffect
      
      if (execution.positions.length) {
        let tempintervalId = setTimeout(()=> {
          setIntervalId(tempintervalId)
        }, 3000);
      }
    }
  }, [execution, intervalId]);

  useEffect(() => {
    if (!modalOptions.open) {
      clearTimeout(intervalId);
      setIntervalId(null);
    }
  }, [modalOptions]);

  const svg = d3.select(svgRef.current);
  // Define variables for your grid.
  const gridSize = 25;
  const gridDotSize = 3;
  const gridColor = '#a4a4a4';

  
  // Call this function to modify the pattern when zooming/panning.
  function updateGrid(zoomEvent) {
    svg.select('#dot-pattern')
      .attr('x', zoomEvent.transform.x)
      .attr('y', zoomEvent.transform.y)
      .attr('width', gridSize * zoomEvent.transform.k)
      .attr('height', gridSize * zoomEvent.transform.k)
      .selectAll('rect')
        .attr('x', (gridSize * zoomEvent.transform.k / 2) - (gridDotSize / 2))
        .attr('y', (gridSize * zoomEvent.transform.k / 2) - (gridDotSize / 2))
        .attr('opacity', Math.min(zoomEvent.transform.k, 1)); // Lower opacity as the pattern gets more dense.
  }

  //console.log(BsPlugin().props.children[0].props.d)

  // let path = d3.path();
  // path.rect(0, 0, 180, 60);
  //let symbolPath = d3.symbol().type(BsPlugin)();


  useEffect(() => {
    if (dragged.current) return () => {};

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Define the arrow marker
    svg.append("defs").append("marker")
    .attr("id", "arrow")
    .attr("viewBox", "0 -5 10 10")
    .attr("refX", 9)
    .attr("refY", 0)
    .attr("markerWidth", 5)
    .attr("markerHeight", 5)
    .attr('orient', 'auto-start-reverse')
    .append("path")
    .attr("d", "M0,-5L10,0L0,5")
    .attr("class", "arrowHead")
    .style("fill", "#000");

    // Create a pattern element, and give it an id to reference later.
    svg.append('pattern')
      .attr('id', 'dot-pattern')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', gridSize)
      .attr('height', gridSize)
      .append('rect') // Notice how we only need to define and modify a single rect.
        .attr('width', gridDotSize)
        .attr('height', gridDotSize)
        .attr('fill', gridColor)
        .attr('x', (gridSize / 2) - (gridDotSize / 2))
        .attr('y', (gridSize / 2) - (gridDotSize / 2));

    // Append a "backdrop" rect to your svg, and fill it with your pattern.
    // You shouldn't need to touch this again after adding it.
    svg.append('rect')
      .attr('fill', 'url(#dot-pattern)')
      .attr('width', '100%')
      .attr('height', '100%');

    // Create a zoom transform from d3.zoomIdentity
    let transform = d3.zoomIdentity.translate(trnasformValue.x, trnasformValue.y).scale(trnasformValue.k);

    // Call d3.zoom to enable zooming/panning in this svg.
    const zoom = d3.zoom()
      .scaleExtent([0.25, 2])
      .on("zoom", (event)=> {
        container.attr('transform', event.transform);
        setTransformValue({...event.transform});
        //transform = d3.zoomIdentity.translate(event.transform.x, event.transform.y).scale(event.transform.k);
        //contents.forEach(z => z.attr('transform', event.transform));
        updateGrid(event); // We need to update the grid with every zoom event.
      });
      

    const container = svg.append('g').attr('id', "container");    
    const contents = [], lineIdsDict = {};
    const tasks = {};

    const drawLineFromArr = (arr=[], z, type="to") => {
      if (!arr.length) return 0;
      
      for(let j = 0; j < arr.length; j++) {
        const taskId = arr[j].trim();
        if (taskId == undefined || !taskId.length) continue;

        let tempTask;

        if (tasks[taskId]) {
          tempTask = tasks[taskId];
          delete tasks[taskId];
        }else {
          tempTask = data.find(task => task.microtask == taskId);
          if (!tempTask) {
            console.log("no task found");
            continue;
          }
          tempTask = (tempTask.vector) ? tempTask.vector : {translateX: 0, translateY: 0};
        }

        let tempLine = lineIdsDict[taskId].find(x => x.connector_id == z.connector_id);

        const x1 = tempTask.translateX+90;
        const y1 = tempTask.translateY+30;

        const x2 = z.vector ? z.vector.translateX+90 : 0;
        const y2 = z.vector ? z.vector.translateY+30 : 0;

        container.append('line')
        .attr("id", tempLine ? tempLine.lineId: "a").attr("data-type", type)
        .style("stroke", "#000")
        .style("stroke-width", 3)
        .attr("x1", type == "to" ? x1-90 : x1)
        .attr("y1", y1)
        .attr("x2", type == "from" ? x2-90 : x2)
        .attr("y2", y2)
        .attr(type == "from" ? "marker-end" : "marker-start" , "url(#arrow)")
      }
    }

    connectors.forEach(z => {
      (z.input_from+","+z.input_to).split(",").forEach(mid => {
        mid = mid.trim();
        if (mid.length > 2) {
          if (lineIdsDict[mid] && lineIdsDict[mid].length) {
            lineIdsDict[mid].push({lineId: `line-${mid}-${Math.floor(Math.random()*999999999999)}`, connector_id: z.connector_id})
          }else {
            lineIdsDict[mid] = [{lineId: `line-${mid}-${Math.floor(Math.random()*999999999999)}`, connector_id: z.connector_id}]
          }
        }
      })
    });


    for(let i = 0; i < data.length; i++) {
      const z = data[i];
      if (!z.connector_id) {
        tasks[z.microtask] = z.vector ? z.vector : {translateX: 0, translateY: 0};
        continue;
      }

      drawLineFromArr(z.input_from.split(","), z, "from");
      drawLineFromArr(z.input_to.split(","), z);
      
    }
    
    data.forEach((z, i) => {
      const currentLineIDs = [];
      // get line ids associated with the container
      if (z.connector_id) {
        (z.input_from+","+z.input_to).split(",").forEach(mid => {
          mid = mid.trim();
          if (mid.length > 2) {
            let tempLine = lineIdsDict[mid].find(x => x.connector_id == z.connector_id);
            currentLineIDs.push(tempLine.lineId);
          }
        });
      }else {
        lineIdsDict[z.microtask] && currentLineIDs.push(lineIdsDict[z.microtask].map(x => x.lineId));

        // adding vectors to create line
        //tasks[z.microtask] = z.vector ? z.vector : {translateX: 0, translateY: 0};
      }
      

      const content = container.append('g')
      .attr('id', 'content'+(i+1)).attr("data-line-ids",  `${currentLineIDs.join(",")}`)
      .attr("transform", `translate(${z.vector ? z.vector.translateX : 0}, ${z.vector ? z.vector.translateY : 0})`)
      .attr('data-type', z.connector_id ? "connectors" : "microtasks").attr('data-id', z.connector_id ? z.connector_id : z.microtask).attr('data-pos', z.position)
      .attr("class", "box-group").on("click", (e) => {handleWidgetClick(z)});

      // color the currently exectued process green
      if (execution && execution.positions.indexOf(z.position) >= 0) {
        content.attr("stroke", "#0fa");
      }


      // Place all svg content inside of a group, adjacent to the backdrop rect.
      content.append('rect').attr("class", "rect").attr('fill', '#ffffff')
        .attr('rx', 7).attr('width', 180).attr('height', 60)
        .attr('x', 0).attr('y', 0)
        .attr('stroke', 'black')
        .attr('stroke-width', 3)
      
      //svg.append("path").attr("d", BsPlugin().props.children[0].props.d);

      content
      .append('text')
      .attr('x', 45) // X-coordinate of the text position
      .attr('y', 36) // Y-coordinate of the text position
      .text(z.microtask ? z.microtask : "connector") // Text content
      .attr('fill', 'black') // Text color
      .attr('font-size', '20px'); // Font size

      content.append("circle")
      .attr("cx", 6)
      .attr("cy", 6)
      .attr("r", 15)
      .attr("class", "circle").attr('fill', '#ffffff')
      .attr('stroke', 'black')
      .attr('stroke-width', 2);

      content
      .append('text')
      .attr('x', 1.5) // X-coordinate of the text position
      .attr('y', 10) // Y-coordinate of the text position
      .text(z.position) // Text content
      .attr('fill', 'black') // Text color
      .attr('font-size', '15px'); // Font size

      contents.push(content);
      //drawLineFromArr((z.input_from+","+z.input_to).split(","), z, currentContainerIDs);
    });

    const dragHandler = d3.drag()
      .on('start', function(event) {
        //console.log('Drag started', event.x, event.y)
      })
      .on('drag', function(event) {
        //event.sourceEvent.stopPropagation(); // silence other listeners
        dragged.current = true;

        const rect = d3.select(this);
        rect.attr("transform", `translate(${event.x-90}, ${event.y-30})`);

        const linesIds = rect.node().getAttribute("data-line-ids").split(",");
        linesIds.forEach(z => {
          if (z.length) {
            const line = d3.select(`#${z.trim()}`);
            if (line.node()) {
              const lineType = line.node().getAttribute("data-type");
              if (rect.node().getAttribute("data-type").includes("connector")) {
                line.attr("x2", lineType == "from" ? event.x-90 : event.x)
                line.attr("y2", event.y)
              }else {
                line.attr("x1", lineType == "to" ? event.x-90 : event.x)
                line.attr("y1", event.y)
              }
            }
          }
        })
      })
      .on('end', function(event, d) {
        let contentGroup = d3.select(this);
        //console.log('Drag ended', event.x, event.y)
        //const [x, y] = d3.pointer(event, svg.node());

        if (!dragged) return 0;
        dragged.current = false;

        editVector(contentGroup.attr("data-type"), contentGroup.attr("data-id"), contentGroup.attr("data-pos")-0,
          [{key: 'vector', value: {translateX: event.x-90, translateY: event.y-30}}]);
          
      });

    // Apply drag behavior to the circle
    container.selectAll('g').call(dragHandler);
    svg.call(zoom).call(zoom.transform, transform);
    // svg.on("mousemove", (e) => {
    //   const [x, y] = d3.pointer(e, svg.node());
    //   console.log(e.x)
    // })

    d3.select('#zoom-in').on('click', function () {
      zoom.scaleBy(svg.transition().duration(600), 1.6);
    });

    d3.select('#zoom-out').on('click', function () {
      zoom.scaleBy(svg.transition().duration(600), 1 / 1.6);
    });
  }, [execution, screen, data]);
  
  return (
    <>
    <section className="d-flex flex-column position-fixed border border-secondary">
      <button id="zoom-in" className="p-2 bg-white">+</button>
      <button id="zoom-out" className="p-2 bg-white">-</button>
    </section>
    <svg ref={svgRef} width={width} height={height}>
      {/* <path fill="none" cx={10} cy={200} stroke="currentColor" strokeWidth="1.5" d={path} className="rect" /> */}
      {/* <g fill="white" stroke="currentColor" strokeWidth="1.5">
        {data.map((d, i) => (<circle key={i} cx={x(i)} cy={y(d)} r="2.5" />))}
      </g> */}
    </svg>
    </>
  );
}