import React, { useState, useEffect } from "react";

import Layout from "../layout";

import "./Search.css";
import { SearchBar } from "./SearchBar";
import { SearchResultsList } from "./SearchResultsList";

function Search() {
  const [showAlert, setShowAlert] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  let menuPermissions = [];

  // Check if "Automation Tasks" is in 0th or 1st index
  if (user && user["user_menu"] && user["user_menu"].length > 0) {
    if (user["user_menu"][0] && user["user_menu"][0]["Automation Tasks"]) {
      menuPermissions = user["user_menu"][0]["Automation Tasks"]["children"];
    } else if (user["user_menu"][1] && user["user_menu"][1]["Automation Tasks"]) {
      menuPermissions = user["user_menu"][1]["Automation Tasks"]["children"];
    }
  }

  useEffect(() => {
    if (!menuPermissions || menuPermissions.length === 0) {
      setShowAlert(true);
    }
  }, [menuPermissions]);

  const [results, setResults] = useState([]);

  return (
    <Layout heading="Tasks Search">
      <div className="search-bar-container">
        <SearchBar setResults={setResults} menuPermissions={menuPermissions} />
        <SearchResultsList results={results} />
      </div>
    </Layout>
  );
}

export default Search;
