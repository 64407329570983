import React, { useState, useEffect } from "react";
import { Button, Nav, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';

import { BsList, BsPersonCircle, BsArrowBarLeft } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";

import "./style.css";


export default function Head({ heading="", collapsed=false, handleCollapse=()=>{} }) {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const menuPermissions = user["user_menu"];


  const handleLogoutClick = () => {
    const payload = {
        email: user.email, // Include the user's email
    };

    fetch("https://biotasks.com/api/logout", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
        if (data.status === "success") {
            console.log("Successfully logged out");

            // Remove user data from local storage and cookies
            localStorage.removeItem("user");
            Cookies.remove('user');

            history.push("/login"); // Redirect to login page
            window.location.reload(); // Reload the page
        } else {
            console.log("Could not log out");
        }
    })
    .catch(error => {
        console.error("There was a problem with the logout request", error);
    });
  };

  const UserAvatar = (
    <img
      src={'/avatar.png'}
      alt="UserName profile image"
      className="nav-avatar-img"
    />
  )

  return (
    <div className="d-flex align-items-center justify-content-between py-3 mb-3 head">
      <button className="menu-btn" title="open menu" onClick={handleCollapse}>
        {
          collapsed
          ?
          <BsList />
          :
          <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
          </svg>
        }
      </button>
      <h1>{heading}</h1>
      <button className="profile-btn" title="log out" onClick={handleLogoutClick}>
          <BsArrowBarLeft />
      </button>
      {/*<section className="d-flex flex-column align-items-center profile-opts-sec">
        <a>Settings</a>
        <button className="logout-btn">Log out</button>
      </section>*/}
    </div>
  );
}
