import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './AdminAllTaskTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "../layout";

function TaskTable() {
  const [tasks, setTasks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasAdminAccess, setHasAdminAccess] = useState(false);
  const [modalInfo, setModalInfo] = useState({ show: false, content: '' });
  const handleCloseModal = () => setModalInfo({ show: false, content: '' });

  const handleShowModal = content => {
    setModalInfo({ show: true, content });
  };
  useEffect(() => {
    const userCookie = Cookies.get('user');
    if (userCookie) {
      const userDetails = JSON.parse(userCookie);
      if (userDetails.admin === 1) {
        setHasAdminAccess(true);
        axios
          .get('https://biotasks.com/api/get_all_microtasks')
          .then(response => {
            const sortedTasks = response.data.sort((a, b) => {
              const partsA = a.microtask.split('.').map(Number);
              const partsB = b.microtask.split('.').map(Number);
              for (
                let i = 0;
                i < Math.min(partsA.length, partsB.length);
                i++
              ) {
                if (partsA[i] !== partsB[i]) {
                  return partsA[i] - partsB[i];
                }
              }
              return partsA.length - partsB.length;
            });
            setTasks(sortedTasks);
          })
          .catch(error => {
            console.error('There was an error fetching the tasks!', error);
          });
      }
    }
  }, []);

  if (!hasAdminAccess) {
    return <p>Access Denied. No admin privileges.</p>;
  }

  return (
    <Layout heading="All Task">
      <div className="row justify-content-center mb-3">
        <div className="col-md-6">
          <input
            className="form-control"
            type="text"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search by Task number"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Task #</th>
                <th>Task Type</th>
                <th>Title</th>
                <th>Description</th>
                <th>Prompt</th>
              </tr>
            </thead>
            <tbody>
              <Modal show={modalInfo.show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Task Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalInfo.content}</Modal.Body>
              </Modal>
              {tasks
                .filter(task =>
                  task.microtask
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase().replaceAll(".","_"))
                )
                .map(task => (
                  <tr key={task._id}>

                    <td><a href={`https://biotasks.com/api/micro_task/${task.microtask.replace(/\./g, '_')}`} target="_blank" rel="noopener noreferrer">
                      {task.microtask.replaceAll( '_','.')}
                    </a></td>
                    <td>{task.template === "chatGPT.html" ? 'chatGPT'
                      : task.template === 'pythontasks.html'
                        ? 'python'
                        : task.template === 'manualtasks.html'
                          ? 'manual'
                          : 'Unknown Type'}</td>
                    <td>
                      <div
                        className="scrollable-cell"
                        onClick={() => handleShowModal(task.title)}
                      >
                        {task.title}
                        <span className="magnify-glass">
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="scrollable-cell"
                        onClick={() => handleShowModal(task.description)}
                      >
                        {task.description}
                        <span className="magnify-glass">
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="scrollable-cell"
                        onClick={() => handleShowModal(task.prompt)}
                      >
                        {task.prompt}
                        <span className="magnify-glass">
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}

export default TaskTable;