import React, { useState, useEffect, useRef } from "react";

import { BsPlugin, BsRobot, BsListTask, BsNodePlus } from "react-icons/bs";
import { FaEdit, FaCopy } from "react-icons/fa";

import ManualTaskContent from "./manualtask_content";
import AITaskContent from "./aitask_content";
import ConnectorContent from "./connector_content";


export default function Widget({ data={}, preview=false, executing=false, screen=0, modalOptions={}, modalKey=1, execution,
  editTaskJSON=()=>{}, deleteTaskJSON=()=>{}, setModalOptions=()=>{}, runAITask=()=>{}, setModalKey=()=>{}
}) {
  const [changes, setChanges] = useState([]);


  let icon = "";
  if (!data.template) {
    icon = <BsPlugin />;
  }else if (data.template.includes("manual")) {
    icon = <BsListTask />;
  }else if(data.template.includes("chatGPT")) {
    icon = <BsRobot />;
  }else if(data.template.includes("connector")) {
    icon = <BsPlugin />;
  }

  const handleDelClick = async (type, taskid, position) => {
    await deleteTaskJSON(type, taskid, position);
  }
  const handleSaveClick = async (type, taskid, position, callback) => {
    try {
      let temp = [];
      changes.forEach(z => {
        if (z.key == "taskInput") {
          if (z.valueRef.current.value.length) {
            temp.push({ key: z.key, value: z.valueRef.current.value.split("\n") });
          }else {
            temp.push({ key: z.key, value: "" });
          }
        }else if(z.key == "input_from" || z.key == "input_to" || z.key == "activation_trigger") {
          temp.push({ key: z.key, value: z.valueRef.current.value.split(".").join("_") });
        }else if(z.key == "combineLine") {
          temp.push({ key: z.key, value: z.valueRef.current.checked });
        }else {
          temp.push({ key: z.key, value: z.valueRef.current.value });
        }
      });
      await editTaskJSON(type, taskid, position, temp);
      
      // reset
      setChanges([]);

      if (callback) callback();
    }catch(err) {
      console.log(err);
    }
  }

  const handleEditClick = (key, elementRef, disable=true) => {
    try {
      disable && (elementRef.current.disabled = !elementRef.current.disabled);

      // add all the fields keys and values, that needs to updated
      if (!changes.find(z => z.key == key)) {
        let temp = [];
        temp.push({ key: key, valueRef: elementRef });
        setChanges(state => {
          state.push({ key: key, valueRef: elementRef });

          return state;
        });
      }
    }catch(err) {
      console.log(err)
    }
  }
  
  const handleWidgetClick = (e, removeChange=true) => {
    removeChange && setChanges(state => {state.length = 0; return state});

    let tempCon;
    if (data.connector_id) {
      tempCon = <ConnectorContent {...data} handleDelClick={handleDelClick} preview={preview} state={executing} screen={screen}
        handleSaveClick={handleSaveClick} handleEditClick={handleEditClick} />
    }else if (data.template.includes("manual")) {
      tempCon = <ManualTaskContent {...data} handleDelClick={handleDelClick} preview={preview} state={executing} screen={screen}
      handleSaveClick={handleSaveClick} handleEditClick={handleEditClick} />;
    }else if (data.template.includes("chatGPT")) {
      tempCon = <AITaskContent {...data} handleDelClick={handleDelClick} preview={preview} state={executing} screen={screen}
      handleSaveClick={handleSaveClick} handleEditClick={handleEditClick} runAITask={runAITask} />
    }
    
    setModalKey(Math.random()*9999999);
    tempCon && setModalOptions((state) => ({
      open: true,
      heading: data.microtask ? data.microtask.split("_").join(".") : "connector",
      content: tempCon,
      data: data
    }));
  }

  useEffect(() => {
    let intervalId;
    if (modalOptions.open) {
      handleWidgetClick("", false); // not updating the change state so it's not flooding useEffect
    }
  }, [executing, changes, data.taskOutput]);


  return (
    <div className={(executing && "executing ")+" widget-divs "} onClick={handleWidgetClick}>
      {
        data.position != 0
        &&
        <span className="widget-pos-span">
          {data.position}
        </span>
      }
      {
        icon
      }
      <p>
        {(data.microtask ? data.microtask.split("_").join(".") : "") || "connector"}
      </p>
    </div>
  );
}