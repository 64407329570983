const endpointBase = process.env.REACT_APP_HOST;
let defaultTemplate = {
    "template_id": "template",
    "name": "template",
    "created_at": new Date(),
    "microtasks": {
        "children": []
    },
    "connectors" : {
        "connectors" : {
            "children": []
        }
    }
};
let defaultProject = {
    "project_id": "id",
    "name": "project",
    "status": false,
    "created_at": new Date(),
    "templates": {
        "children": [
            {
                "template_id": "defaulttemplate",
                "name": "default template",
                "created_at": new Date(),
                "microtasks": {
                    "children": []
                },
                "connectors" : {
                    "connectors" : {
                        "children": []
                    }
                }
            }
        ]
    }
}

const createProject = async (id=null, name=null, data=defaultProject) => {
    let returnJson = null;
    try {
        if (!data || !id || !name) return 0;

        delete data["_id"];
        data.project_id = id;
        data.name = name;

        let res = await fetch(endpointBase+"/api/create_project", {
            method: "POST",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify(data)
        });
        returnJson = await res.json();
    }catch(err) {
        console.log(err);
    }finally {
        return returnJson;
    }
};

const getAllProjects = async () => {
    let returnJson = null;
    try {
        let res = await fetch(endpointBase+"/api/list_all_projects");
        returnJson = await res.json();
    }catch(err) {
        console.log(err);
        returnJson = [defaultProject];
    }finally {
        return returnJson;
    }
};
const getProject = async (projectName = null) => {
    let returnJson = null;
    try {
        if (!projectName) throw "no name";

        let res = await fetch(endpointBase+"/api/pull_project/"+projectName);
        returnJson = await res.json();
    }catch(err) {
        console.log(err);
        returnJson = defaultProject;
    }finally {
        return returnJson;
    }
};
const updateProject = async (data=null) => {
    let returnJson = null;
    try {
        if (!data) return 0;

        delete data["_id"];

        let res = await fetch(endpointBase+"/api/modify_project", {
            method: "POST",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify(data)
        });
        returnJson = await res.json();
    }catch(err) {
        console.log(err);
    }finally {
        return returnJson;
    }
};
const deleteProject = async (projectName = null) => {
    let returnJson = null;
    try {
        if (!projectName) return 0;

        let res = await fetch(endpointBase+"/api/delete_template/"+projectName);
        returnJson = await res.json();
        console.log(returnJson, projectName)
    }catch(err) {
        console.log(err);
    }finally {
        return returnJson;
    }
};

export { createProject, getAllProjects, getProject, updateProject, deleteProject };