
import { useState, useEffect } from 'react';

const useCopy = () => {
    const copyToClipboard = (text="") => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Copied to clipboard!');
        }).catch((error) => {
            console.error('Error copying to clipboard:', error);
        });
    };

    return copyToClipboard;
};

export default useCopy;
