import React, { useState, useEffect, useRef } from "react";


export default function Modal({ heading="", content="", setModalOptions=()=>{} }) {
    const handleCloseClick = () => {
      // reset the modal
      setModalOptions((state) => ({
        heading: "",
        content: "",
        open: false,
        data: null,
      }));
    }
  
    return (
      <>
      <div className="project-modal-div">
        <section className="d-flex align-items-center justify-content-between gap-3">
          <span></span>
          <h4 className="m-0">{heading}</h4>
          <button className="btn btn-secondary" onClick={handleCloseClick}>Close</button>
        </section>
        <hr />
        {
          content
        }
      </div>
      </>
    );
  }