import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

import "./SearchBar.css";

export const SearchBar = ({ setResults, menuPermissions }) => {
  const myVar = [];

  menuPermissions.forEach((obj) => {
    const key = Object.keys(obj)[0];
    const item = obj[key];
    if (item.children) {
      const tasksArray = item.children;
      tasksArray.forEach((subObj) => {
        const subKey = Object.keys(subObj)[0];
        const subItem = subObj[subKey];
        if (subItem.children) {
          const subTasksArray = subItem.children;
          subTasksArray.forEach((subSubObj) => {
            const subSubKey = Object.keys(subSubObj)[0];
            const subSubItem = subSubObj[subSubKey];
            const tasksList = subSubItem.children;
            tasksList.forEach((task) => {
              const taskKey = Object.keys(task)[0];
              const taskItem = task[taskKey];
              myVar.push({ name: taskItem.text, link: taskItem["link"].url });
            });
          });
        }
      });
    }
  });

  const [input, setInput] = useState("");

  const fetchData = (value) => {
    const results = myVar.filter((user) => {
      return (
        value && user && user.name && user.name.toLowerCase().includes(value)
      );
    });

    setResults(results);
  };

  const handleChange = (value) => {
    setInput(value);
    value = value.replaceAll(".","_")
    fetchData(value);
  };

  return (
    <div className="input-wrapper">
      <FaSearch id="search-icon" />
      <input
        placeholder="Type to search..."
        value={input}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};
