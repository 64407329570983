import React, { useState, useEffect, useRef } from "react";
import { FaEdit, FaCopy } from "react-icons/fa";

import useCopy from "../../hooks/useCopy";

export default function AITaskContent({
    macrotask= "",
    microtask= "",
    description= "",
    template= "",
    title= "",
    response= "",
    model= "chatgpt",
    prompt= "",
    input= "",
    output= "",
    taskInput= [],
    taskOutput= "",
    numberOfExecutedTask=0,
    numberOfInput=0,
    position="",
    executionState=false,
    status="notcomplete",
    combineLine=false,

    view=null,
    screen=0,
    preview=false,
    handleDelClick=()=>{},
    handleSaveClick=()=>{},
    handleEditClick=()=>{},
    runAITask=()=>{}
  }) {
    const positionRef = useRef();
    const titleRef = useRef();
    const desRef = useRef();
    const promptRef = useRef();
    const inpRef = useRef();
    const combineLineRef = useRef();
    const resRef = useRef();
    const statusRef = useRef();
    const modelRef = useRef();

    const [inputLines, setInputLines] = useState((typeof(taskInput) == "string" && taskInput.length) ? taskInput.split("\n").length : taskInput.length);
    const [outputLines, setOutputLines] = useState(taskOutput.length ? taskOutput.split(';').join("\n").split("\n").length : 0);
    const [combineLineState, setCombineLineState] = useState(combineLine);
  
    const copyToClipboard = useCopy();

    const handleInputChange = (e) => {
      let tempInp = e.target.value;
      setInputLines(tempInp.length ? tempInp.split("\n").length : 0);
    }
    const handleOutputChange = (e) => {
      let tempOut = e.target.value;
      setOutputLines(tempOut.length ? tempOut.split("\n").length : 0);
    }
    
    const handleStatusClick = (e) => {
      document.querySelectorAll(".widget-status-radio").forEach(z => {
        z.checked=false;
      });
  
      e.target.checked = true;
    }

    // useEffect(()=> {
    //   if (combineLineState) {
    //     resRef.current.value = taskOutput.split(';').join(",");
    //   console.log(resRef.current.value)
    // }else {
    //     resRef.current.value = taskOutput.split(';').join("\n");
    //   console.log(resRef.current.value)
    // }
    // }, [combineLineState]);

    useEffect(() => {
      let obj = {}, inps=[...taskInput], temp = [], visited=[];
      taskOutput.split(";").forEach(z => {
        obj =  Object.fromEntries([ [z.split(":")[0], z.split(":").slice(1).join(":")] ]);
        for (let index=0; index < inps.length; index++) {
          let z = inps[index];

          if (visited.includes(index)) {
            continue;
          }

          if (obj[z]) {
            visited.push(index);
            temp[index] = `${z}: ${obj[z]}`;
            break;
          }
          
        }
        //temp = taskInput.map((term, i) => obj[term] || ori[i] || `${term}: No search result found`);
        //ori = temp;
      });
      resRef.current && (resRef.current.value = temp.join("\n"))
      setOutputLines(temp.join("\n").length ? temp.join("\n").split("\n").length : 0)
      
    }, [taskOutput]);

    useEffect(() => {
      statusRef.current.value = status;
    }, [status]);

    return (
      <div className="d-flex flex-column gap-3 align-items-start h-100">
        <div className="d-flex align-items-center justify-content-between gap-3 w-100">
          <section className="d-flex gap-1">
            <p>Position:</p>
            <input defaultValue={position} type="number" className="border border-dark" min={1}
              ref={positionRef} onChange={ () => {handleEditClick("position", positionRef, false)} }
              disabled={preview || (screen == 0)}
              />
          </section>
          <section className="d-flex gap-1">
            <p>Task Type:</p>
            <strong>ChatGPT Task</strong>
          </section>
          <section className="d-flex gap-1">
            <p>MicroTask Number:</p>
            <a href="#">{microtask}</a>
          </section>
        </div>
        
        {/* {
          (screen == 0)
        } */}
        <section className="w-100">
          <section className="">
            <p className="d-inline">MicroTask Title:</p>
            {
              (!preview && screen == 1 && !executionState) &&
              <>
                <button className="ms-2" onClick={ () => {handleEditClick("title", titleRef)} }>
                  <FaEdit />
                </button>
                <button className="ms-2" onClick={ () => {copyToClipboard(titleRef.current.value)} }>
                  <FaCopy />
                </button>
              </>
            }
          </section>
          <input type="text" defaultValue={title} className="w-100 border border-dark" ref={titleRef} disabled />
        </section>
        <section className="w-100">
          <section className="">
            <p className="d-inline">MicroTask Description:</p>
            {
              (!preview && screen == 1 && !executionState) &&
              <>
                <button className="ms-2" onClick={ () => {handleEditClick("description", desRef)} }>
                  <FaEdit />
                </button>
                <button className="ms-2" onClick={ () => {copyToClipboard(desRef.current.value)} }>
                  <FaCopy />
                </button>
              </>
            }
          </section>
          <textarea type="text" defaultValue={description} className="w-100 border border-dark" disabled ref={desRef}></textarea>
        </section>
  
        <section className="w-100">
          <section className="">
            <p className="d-inline">Prompt:</p>
            {
              (!preview && screen == 1 && !executionState) &&
              <>
                <button className="ms-2" onClick={ () => {handleEditClick("prompt", promptRef)} }>
                  <FaEdit />
                </button>
                <button className="ms-2" onClick={ () => {copyToClipboard(promptRef.current.value)} }>
                  <FaCopy />
                </button>
              </>
            }
          </section>
          <textarea type="text" defaultValue={prompt} className="w-100 border border-dark" disabled ref={promptRef}
            ></textarea>
        </section>
        {
          view != 2 &&
          <>
            <section className="w-100">
              <section className="w-100 d-flex justify-content-between">
                <section className="">
                  <p className="d-inline">Input:</p>
                  {
                    (!preview && screen == 0 && !executionState) &&
                    <>
                      <button className="ms-2" onClick={ () => {handleEditClick("taskInput", inpRef)} }>
                        <FaEdit />
                      </button>
                      <button className="ms-2" onClick={ () => {copyToClipboard(inpRef.current.value)} }>
                        <FaCopy />
                      </button>
                    </>
                  }
                </section>
                <code>{inputLines} lines</code>
              </section>
              <textarea type="text" ref={inpRef} defaultValue={typeof(taskInput) == "string" ? taskInput : taskInput.join("\n")} className="w-100 border border-dark" disabled
                onChange={handleInputChange}></textarea>
            </section>
      
            <section className="d-flex w-100">
              <p className="d-inline">Choosen AI Chat Models:</p>
              <select className="ms-2" defaultValue={model} ref={modelRef} disabled={preview || executionState}
                onChange={ () => {handleEditClick("model", modelRef, false)} }>
                <option value={""}>None</option>
                <option value={"chatgpt"}>Chat GPT</option>
                <option value={"claude"}>Claude</option>
                <option value={"gemini"} disabled>Gemini</option>
                <option value={"llama"} disabled>Llama</option>
              </select>
              {/* <label className="d-flex align-items-center ">
                <input type="radio" className="ms-2 widget-model-radio" onClick={handleModelClick} />
                Chat GPT
              </label>
              <label className="d-flex align-items-center ">
                <input type="radio" className="ms-2 widget-model-radio" onClick={handleModelClick} />
                Claude
              </label>
              <label className="d-flex align-items-center ">
                <input type="radio" className="ms-2 widget-model-radio" onClick={handleModelClick} />
                Gemini
              </label>
              <label className="d-flex align-items-center ">
                <input type="radio" className="ms-2 widget-model-radio" onClick={handleModelClick} />
                LLama
              </label> */}
            </section>
      
            <section className="w-100">
              <section className="w-100 d-flex justify-content-between">
                <section className="d-flex align-items-center">
                  <p className="d-inline">Response:</p>
                  {
                    (!preview && screen == 0 && !executionState) &&
                    <>
                      <button className="ms-2" onClick={ () => {handleEditClick("taskOutput", resRef)} }>
                        <FaEdit />
                      </button>
                      <button className="ms-2" onClick={ () => {copyToClipboard(resRef.current.value)} }>
                        <FaCopy />
                      </button>
                      <label className="d-inline-flex align-items-center gap-2 ms-3">
                        Combine Lines:
                        <input type="checkbox" defaultChecked={combineLine} ref={combineLineRef} disabled={preview || executionState}
                        onChange={ (e) => {setCombineLineState(e.target.checked); handleEditClick("combineLine", combineLineRef, false)} } />
                      </label>
                    </>
                  }
                </section>
                <code>{outputLines} lines</code>
              </section>
              <textarea type="text" className="w-100 border border-dark" disabled
                ref={resRef} onChange={handleOutputChange} ></textarea>
            </section>
          </>
        }
  
        <div className="d-flex justify-content-around w-100">
          <section className="d-flex">
            {/* <p className="">Widget State:</p>
            <select className="ms-2" defaultValue={state} ref={statusRef} disabled={preview}>
              <option value={"false"}>Inactive</option>
              <option value={"true"}>Active</option>
            </select> */}
            <p className="">Widget State:</p>
            <label className="d-flex align-items-center text-danger">
              <input type="radio" className="ms-2 widget-status-radio" checked={!executionState ? true : false} readOnly />
              Inactive
            </label>
            <label className="d-flex align-items-center text-success">
              <input type="radio" className="ms-2 widget-status-radio" checked={executionState ? true : false} readOnly />
              Active
            </label>
          </section>
          {
            ( !preview && screen == 0 ) &&
            <section>
              <p>
                (
                  {
                    numberOfExecutedTask+" "
                  }
                  of {numberOfInput} task completed
                )
              </p>
            </section>
          }
          <section className="d-flex">
            <p className="">Widget Status:</p>
            {/* <label className="d-flex align-items-center ">
              <input type="radio" className="ms-2 widget-status-radio" onClick={handleStatusClick} />
              Not Completed
            </label>
            <label className="d-flex align-items-center ">
              <input type="radio" className="ms-2 widget-status-radio" onClick={handleStatusClick} />
              Completed
            </label> */}
            <select className="ms-2" defaultValue={status} ref={statusRef} disabled={preview}
              onChange={ () => {handleEditClick("status", statusRef, false)} }>
              <option value={"notcomplete"}>Not Complete</option>
              <option value={"complete"}>Complete</option>
            </select>
          </section>
        </div>
  
        {
          !preview &&
          <section className="d-flex justify-content-around mt-3 w-100">
            <button className="btn btn-success" disabled={preview}
              onClick={ ()=>{handleSaveClick("microtasks", microtask, position)} }>
              Save / Update
            </button>
            {
              (screen == 0) &&
              <button className="btn btn-primary" disabled={preview}
                onClick={ ()=> {runAITask({microtask, prompt, input: inpRef.current.value.split("\n"), position, model: modelRef.current.value, combineLine: combineLineRef.current.checked}, true)} }>
                Run Task
              </button>
            }
            {
              screen == 1 &&
              <button className="btn btn-danger" disabled={preview}
              onClick={ ()=> {handleDelClick("microtasks", microtask, position)} }>
                Delete
              </button>
            }
          </section>
        }
      </div>
    );
}