import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";


import "bootstrap/dist/css/bootstrap.min.css";

import { getProject, updateProject } from "../../../libs/projects";
import { getAllTemplates, getTemplate } from "../../../libs/templates";
import { executePipeline } from "../../../libs/pipline";
import { updateTaskPosition } from "../../../libs/microtasks";

import useTaskParser from "../../../hooks/useTemplateParser";

import Layout from "../../layout";
import Modal from "../../modals/modal1";

import ProjectNavs from "./navs";
import AddWidget from "./add_widget";
import Card from "../cards";
import Canvas from "./canvas";


import "./style.css";

const intervalIds = [];


export default function ProjectPage() {
  const [screen, setScreen] = useState(0); // 0: all projects, 1: connectors/widgets, 2: wireframe
  const [modalOptions, setModalOptions] = useState({
    open: false,
    heading: "",
    content: ""
  });
  const [execution, setExecution] = useState({notProject: null, positions: []});
  const [projectJSON, setProjectJSON] = useState(null);
  const [templateJSON, setTemplateJSON] = useState(null);
  const [templateList, setTemplateList] = useState([]);
  const [view, setView] = useState(1); //1: grid, 2: half, 3: full
  const [message, setMessage] = useState("");
  const [modalKey, setModalKey] = useState(1);
  const [sortedData, setSortedData] = useState([]);

  const { projectId } = useParams();
  const parser = useTaskParser();

  useEffect(()=> {
    getProject(projectId).then(async (data) => {
      setProjectJSON(data);
      if (data.templates.children[0]) {
        setTemplateJSON(data.templates.children[0]);
      }else {        
        setTemplateJSON(await getTemplate());
      }
    });

    // getting all template list to show them in dropdown in settings
    getAllTemplates().then(async data => {
      setTemplateList(data);
    });
  }, []);

  const editVector = async (type=null, id=null, position=null, changes=null) => {
    try {
      if (!id || !position || !type || !changes || !changes.length) return 0;

      const payload = {
        "project_id": projectJSON.project_id,
        "name": projectJSON.name,
        "template_id": templateJSON.template_id,
        "microtask_id": id,
        ...changes[0].value
      }

      let templateCopy = {...templateJSON}, tempPos = 0;

      const array = type == "connectors" ? templateCopy[type].connectors.children : templateCopy[type].children;
      for (let index = 0; index < array.length; index++) {
        let z = array[index];
        if ( [...Object.values(z)].includes(id) && [...Object.values(z)].includes(position) ) {
          // making the changes
          changes.forEach(y => {
            z[y.key] = y.value;
          });
          break;
        }
      }
      
      // update db
      const data = await updateTaskPosition(payload);
      if (!data) throw ("error while updating project in database!");

      setTemplateJSON(state => state = {...templateCopy});
      setProjectJSON(state => {
        for (let index = 0; index < state.templates.children.length; index++) {
          let z = state.templates.children[index];
          if (z.template_id==templateCopy.template_id) {
            state.templates.children[index] = templateCopy;
            return state;
          }
        }
      });
      
      
    } catch (error) {
      setMessage(error);
    }
  }
  const editTaskJSON = async (type=null, id=null, position=null, changes=null) => {
    try {
      if (execution.positions.length) {
        return 1;
      }
      if (!id || !position || !type || !changes || !changes.length) return 0;

      let templateCopy = JSON.parse(JSON.stringify(templateJSON)), tempPos = 0;

      const array = type == "connectors" ? templateCopy[type].connectors.children : templateCopy[type].children;
      let toChange = null, toChangeIndex;

      for (let index = 0; index < array.length; index++) {
        let z = array[index];
        if ( [...Object.values(z)].includes(id) && [...Object.values(z)].includes(position) ) {
          toChange = {data: {...z}, index, type, id}; // old data (before any changes)
          // getting the old index as well
          for (let i = 0; i < sortedData.length; i++) {
            let x = sortedData[i];
            
            if (x.connector_id == toChange.id) {
              toChangeIndex = i;
              break;
            }else if (x.microtask == toChange.id && x.position == toChange.data.position) {
              toChangeIndex = i;
              break;
            }
          }

          // making the changes
          changes.forEach(y => {
            if (y.key == "position") { //fixing type
              y.value = parseInt(y.value);
              tempPos = z[y.key] - y.value; // original-new
            }
            z[y.key] = y.value;
          });
          break;
        }
      }

      // only triggered if position is changed
      if (tempPos !== 0) {
        // tempPos is actually the new position now 
        if (toChangeIndex == null || toChangeIndex == undefined) throw "some error";

        if (tempPos < 0) {// going up

          for (let index = toChangeIndex+1; index <= Math.abs(tempPos)+toChangeIndex; index++) {
            let z = sortedData[index];
            
            if (!z) continue;

            if (z.position !== index) {
              if (z.connector_id) {
                let temp = templateCopy["connectors"].connectors.children.find(y => y.connector_id == z.connector_id);
                temp["position"] -= 1;
              }else {
                let temp = templateCopy["microtasks"].children.find(y => (y.microtask == z.microtask && y.position == z.position) );
                temp["position"] -= 1;
              }
            }
          }

        }else if (tempPos > 0) {//going down

          for (let index = toChangeIndex-1; index >= Math.abs(tempPos-toChangeIndex); index--) {
            let z = sortedData[index];
            
            if (!z) continue;

            if (z.position !== index) {
              if (z.connector_id) {
                let temp = templateCopy["connectors"].connectors.children.find(y => y.connector_id == z.connector_id);
                temp["position"] += 1;
              }else {
                let temp = templateCopy["microtasks"].children.find(y => (y.microtask == z.microtask && y.position == z.position) );
                temp["position"] += 1;
              }
            }
          }

        }
      }

    
      // update db
      const tempProject = JSON.parse(JSON.stringify(projectJSON));
      for (let index = 0; index < tempProject.templates.children.length; index++) {
        const x = tempProject.templates.children[index];
        
        if (x.template_id == templateCopy.template_id) {
          tempProject.templates.children[index] = templateCopy;
          break;
        }
      }

      const data = await updateProject(tempProject);
      if (!data) throw ("error while updating project in database!");
      setTemplateJSON(state => state = {...templateCopy});
      setProjectJSON(state => {
        for (let index = 0; index < state.templates.children.length; index++) {
          let z = state.templates.children[index];
          if (z.template_id==templateCopy.template_id) {
            state.templates.children[index] = templateCopy;
            return state;
          }
        }
      });
      // if (tempPos != 0) {
      //   // reset the modal
      //   setModalOptions({open: false, heading: "", content: ""});
      // }
      setMessage("updated");
      
      
    } catch (error) {
      setMessage(error);
    }
  }
  const deleteTaskJSON = async (type=null, id=null, position=null) => {
    try {
      if (!id || !type || !position) return 0;

      let templateCopy = {...templateJSON};

      // common primary key
      let array = type == "connectors" ? templateCopy[type].connectors.children : templateCopy[type].children;
      for (let index = 0; index < array.length; index++) {
        let z = array[index];
        if ( [...Object.values(z)].includes(id) && [...Object.values(z)].includes(position) ) {
          array.splice(index, 1);
          break;
        }
      }

      updateProject(projectJSON).then(data => {
        if (!data) {
          throw ("error while updating project in database!");
        }else {
          // reset the modal
          setModalOptions((state) => ({
            heading: "",
            content: "",
            open: false,
            data: null
          }));
          setTemplateJSON(state => state = {...templateCopy});
          setProjectJSON(state => {
            for (let index = 0; index < state.templates.children.length; index++) {
              let z = state.templates.children[index];
              if (z.template_id==templateCopy.template_id) {
                state.templates.children[index] = templateCopy;
                return state;
              }
            }
          });
          setMessage("updated");
        }
      });
    } catch (error) {
      setMessage(error);
    }
  }


  const runAITask = async ({microtask, prompt, input, position, combineLine=false, model}, manual=false) => {
    try {
      //const templateCopy = JSON.parse(JSON.stringify(templateJSON));
      
      setTemplateJSON(state => {
        // these operations below are changing the state in-place, basically changing the original state;
        const toChange = state.microtasks.children.find(z => z.position == position && z.microtask == microtask);
        toChange.taskInput = input;
        toChange.taskOutput = "";
        toChange.status = "notcomplete";
        toChange.numberOfExecutedTask = 0;

        // const tempProject = JSON.parse(JSON.stringify(projectJSON));
        // for (let index = 0; index < tempProject.templates.children.length; index++) {
        //   const x = tempProject.templates.children[index];
          
        //   if (x.template_id == state.template_id) {
        //     tempProject.templates.children[index] = state;
        //     break;
        //   }
        // }

        // updateProject(tempProject).then(data => {
        //   if (!data || data.error) {
        //     throw "error!";
        //   }
        // });

        return state;
      });

      if (!prompt.length || !input.length) {
        setMessage("fields missing!");
        throw "fields missing!!";
      }
      console.log("running..", microtask)
      setMessage("running AI task...", microtask);

      const pipelineInpData = {
        project_id: projectJSON.project_id,
        template_id: templateJSON.template_id,
        microtask: microtask,
        input: input,
        position: position,
        combineLine: combineLine,
        model: model
      }
      
      const returnedData = await executePipeline(pipelineInpData);
      console.log(returnedData)

      if (returnedData.error) {
        setMessage("some error please try again or contact support!");
        throw returnedData.error;
      }

      if (manual) {
        setExecution({positions: [position], notProject: true});
      }

    }catch (err) {
      console.log(err);
      setExecution({positions: []});
    }
  }
  const runConnector = async (current) => {
    try {
      setMessage("running connector...", current.connector_id);
      //find the input_from task and take it's output
      let out = "";
      const inputFromTasks = [];
      const templateCopy = JSON.parse(JSON.stringify(templateJSON));

      current.input_from.split(',').forEach(x => {
        inputFromTasks.push( templateCopy.microtasks.children.find( z => z.microtask == x.trim() ) );
      }); //&& z.position == current.position-1
      
      if (!inputFromTasks.length) {
        setMessage("cannot get input from the specified task");
        throw "cannot get input from the specified task";
      }

      inputFromTasks.forEach(z => {
        if (z.template.includes("chatGPT.html")) {
          out += (z.taskOutput.length ? ( z.taskOutput.split(/[;?\n]+/).map( z => z.split(":").slice(-1) ) ).join("\n") + "\n" : ""); //
        }else {
          out += (z.output +"\n");
        }
      });
      

      //find the input_to task and update it's input
      const inputToTasks = [];
      current.input_to.split(',').forEach(x => {
        inputToTasks.push( templateCopy.microtasks.children.find(z => (z.microtask == x.trim())) );
      }); // && z.position > current.position
      
      if (!inputToTasks.length) {
        setMessage("cannot send output to the specified task");
        throw "cannot send output to the specified task";
      }
      
      inputToTasks.forEach(z => {
        if (z.template.includes("chatGPT.html")) {
          // chatGPT task takes array as an input, instead of string 
          z.taskInput = out.split("\n");
        }else {
          z.input = out;
        }
      });


      // if automatic status complete trigger is enabled 
      if (current.automatic_status_update_button == "true") {
        inputFromTasks.forEach(z => {
          if (z.numberOfExecutedTask >= z.numberOfInput) {
            z.status = "complete";
          }
        });
      }

      const tempProject = JSON.parse(JSON.stringify(projectJSON));
      for (let index = 0; index < tempProject.templates.children.length; index++) {
        const x = tempProject.templates.children[index];
        
        if (x.template_id == templateCopy.template_id) {
          tempProject.templates.children[index] = templateCopy;
          break;
        }
      }
      //console.log( JSON.stringify(tempProject, null, 2) );
      
      updateProject(tempProject).then(data => {
       // console.log(data)
        if (!data || data.error) {
          throw "error!";
        }else {
          setTemplateJSON(templateCopy);
          setProjectJSON(tempProject);

          setMessage("updated input/output");
          console.log('updated input/output');
          
          if (!current.input_to.split(",").length) {
            setExecution({positions: []});
            return 0; // execution complete
          }

          const pos = [];
          current.input_to.split(",").forEach(z => {
            const task = templateCopy.microtasks.children.find(y => y.microtask == z.trim());
            pos.push(task);
          })

          //setExecution({ positions: pos });
          runProject(pos);
        }
      });
    }catch(err) {
      console.log(err);
      intervalIds.forEach(x => clearInterval(x.intervalId));
      intervalIds.length=0;
      setExecution({ positions: [] });
    }
  }

  const runProject = async (toRun=[]) => {
    try {
      setMessage("started execution...");
      console.log("started execution...", toRun);

      if (!toRun || !toRun.length) {
        setMessage("execution complete");
        console.log("execution complete");
        setExecution({ positions: [] });
        return 0;
      }

      toRun.forEach(x => {
        if (!x.template && x.connector_id) {
          if (x.input_to.length > 3) {
            runConnector(x);
          }else {
            throw "no output task"

          }
        }else if (x.template.includes("manualtasks.html")) {
          setMessage("running Manual task...");
          // do something
          // setTimeout(() => {
          //   setExecution({ positions: [current.position+1] });
          // }, 500)
        }else if (x.template.includes("chatGPT.html")) {
          runAITask({
            microtask: x.microtask,
            prompt: x.prompt,
            input: x.taskInput,
            position: x.position,
            combineLine: x.combineLine,
            model: x.model
          });
        }
      });

      setExecution({positions: toRun.map(z => z.position)});
    }catch(err) {
      setMessage(err);
      console.log(err);
      //alert(err);
    }
  }

  const handleRunClick = async () => {
    const pos = [];
    templateJSON.connectors.connectors.children[0].input_from.split(",").forEach(z => {
      const task = templateJSON.microtasks.children.find(y => y.microtask == z.trim());
      pos.push(task);
    });
    
    intervalIds.forEach(x => clearInterval(x.intervalId));
    intervalIds.length = 0;
    runProject(pos);
  }

  useEffect(() => {
    if(execution.positions.length == 0) {
      intervalIds.forEach(x => clearInterval(x.intervalId));
      intervalIds.length = 0;
    }else if(execution.positions.length) {
      let z = templateJSON.connectors.connectors.children.find(l => l.position == execution.positions[0]); // assuming if one is connector then rest are too.
      if (z) {
        return () => {};
      }
      
      // fetch and update data every 6 sec
      let intervalId = setInterval(async ()=> {
        intervalIds.push({intervalId});

        setModalKey(Math.random()*999999);
        setMessage("fetching updated data, please be patient...");
        console.log("fetching");
        
        let fetchedData = await getProject(projectId);
        let activeTemplate = fetchedData.templates.children.find(z => (z.template_id == templateJSON.template_id));

        setProjectJSON(state => {
          for (let index = 0; index < state.templates.children.length; index++) {
            let z = state.templates.children[index];
            if (z.template_id == activeTemplate.template_id) {
              state.templates.children[index] = activeTemplate;
              return state;
            }
          }
        });
        setTemplateJSON(state => state = activeTemplate);
      }, 3000);
      
    }
  }, [execution]);

  // checking AI task execution and updating data
  useEffect(() => {
    if (templateJSON) {
      setSortedData(parser(templateJSON));
    }
    if (execution.positions.length == 0 && intervalIds.length) {
      intervalIds.forEach(x => clearInterval(x.intervalId));
      intervalIds.length=0;
    }
    if (execution.positions.length == 0 || !templateJSON) return () => {};

    console.log("updating template...")
    setExecution(state => {
      if (!state.positions.length) return state;

      for (let index = 0; index < state.positions.length; index++) {
        const currentExecPosition = state.positions[index];
        
        let z = templateJSON.microtasks.children.find(l => l.position == currentExecPosition);
        if (!z) {
          continue;
        }
      
        if ( z.numberOfExecutedTask >= z.numberOfInput ) {
          setMessage(z.microtask + " execution complete");
          console.log(z.microtask + " execution complete");
        
          // if (manual) {
          //   setExecution({position: null});
          //   setMessage("execution complete");
          //   console.log("execution complete");

          //   // run connector if auto activation is enabled and status is complete
          //   if (screen == 0 && toChange.template && toChange.status == "complete" && (toChange.template.includes("chatGPT") || toChange.template.includes("manualtasks")) ) {
          //     let ascData = [...sortedData], current = ascData.find(z => ( z.activation_trigger == toChange.microtask ));//&& execution.taskId == z.microtask
          //     if (current) {
          //       setExecution({position: current.position, notProject: true});
          //       runConnector(current, ascData);
          //     }
          //   }
          // }else {
          //   setExecution({position: (position+1)});
          // }
          state.positions.splice(index, 1);
          if(state.positions.length == 0) {
            //console.log(JSON.stringify(intervalIds, null, 2), JSON.stringify(tempExes, null, 2))
            intervalIds.forEach(x => clearInterval(x.intervalId));
            intervalIds.length = 0;
          }

          // run connector if auto activation is enabled and status is complete
          if ( screen == 0 && z.template && (z.template.includes("chatGPT") || z.template.includes("manualtasks")) ) {
            const connector = templateJSON.connectors.connectors.children.find(p => (
              ( p.activation_trigger.split(",").map(q => q.trim()) ).indexOf(z.microtask) >= 0 && p.automatic_status_update_button == "true" )
            );
            if (connector && (state.positions.length == 0)) {
              runProject([connector]);
              ///setExecution({ positions: [connector.position] });
              //runConnector(connector, ascData);
            }
          }
          
        }
        return state;
      }
    });
  }, [templateJSON]);


  let timeoutId;
  useEffect(() => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      message.length && setMessage(state => state = "");
    }, 5000);
  }, [message]);

  
  if (!projectJSON) return (<></>);
  return (
    <Layout heading={(templateJSON ? (projectJSON.name +" - "+ templateJSON.name) : projectJSON.name)}>
      <div className="overflow-hidden project-div">
        {
          // as the key is random, it automatically changes the current modal data
          modalOptions.open &&
          <Modal key={"modalKey"}
            setModalOptions={setModalOptions} {...modalOptions}
          />
        }
        {/**/}
        <ProjectNavs
          screen={screen} projectJSON={projectJSON} templateJSON={templateJSON} templateList={templateList}
           setScreen={setScreen} setProjectJSON={setProjectJSON} setTemplateJSON={setTemplateJSON} setModalOptions={setModalOptions} setView={setView}
        />

          <br/>
        
        {
          screen === 1
          &&
          (
            <AddWidget setModalOptions={setModalOptions} setProjectJSON={setProjectJSON} setTemplateJSON={setTemplateJSON}
             projectJSON={projectJSON} templateJSON={templateJSON} />
          )
        }

        {
          view > 1
          ?
          <div className="content-container-div">
            {
              sortedData.map((z, i) => z && 
                // view == 1 ? <Widget key={i} data={{...z}} executing={z.position == execution.position} screen={screen}
                //   editTaskJSON={editTaskJSON} deleteTaskJSON={deleteTaskJSON} setModalOptions={setModalOptions}
                //   modalKey={modalKey} setModalKey={setModalKey} execution={execution}
                //   runAITask={runAITask} preview={!projectJSON.templates.children.length} modalOptions={modalOptions} /> :
                view == 2 ? <Card key={Math.random()*99999} data={{...z}} executing={execution.positions.indexOf(z.position) >= 0} screen={screen}
                  editTaskJSON={editTaskJSON} deleteTaskJSON={deleteTaskJSON} setModalOptions={setModalOptions}
                  runAITask={runAITask} view={view} preview={!projectJSON.templates.children.length} /> :
                <Card key={Math.random()*99999} data={{...z}} executing={execution.positions.indexOf(z.position) >= 0} screen={screen}
                  editTaskJSON={editTaskJSON} deleteTaskJSON={deleteTaskJSON} setModalOptions={setModalOptions}
                  runAITask={runAITask} preview={!projectJSON.templates.children.length} />
              )
            }
          </div>
          :
          <div className="content-container-div canvas">
            {
              sortedData.length
              ?
              <Canvas key={"canvas"} data={sortedData} screen={screen} editVector={editVector}
              editTaskJSON={editTaskJSON} deleteTaskJSON={deleteTaskJSON} setModalOptions={setModalOptions}
              modalKey={modalKey} setModalKey={setModalKey} execution={execution}
              runAITask={runAITask} preview={!projectJSON.templates.children.length} modalOptions={modalOptions}
              connectors={templateJSON.connectors.connectors.children} microtasks={templateJSON.microtasks.children} />
              :
              ""
            }
          </div>
        }
        <br />
        <section className="d-flex justify-content-between">
          <span></span>
          {message.length ? <p className="d-inline bg-secondary p-2 text-light m-0">{message}</p> : <></>}
          {
            (projectJSON.templates.children.length && screen == 0) ?
            <button className="btn btn-primary" onClick={() => {handleRunClick()}}>Run Project</button>
            :
            <span></span>
          }
        </section>
        
      </div>
    </Layout>
  );
}
