import React, { useState, useEffect, useRef } from "react";

import { BsPlugin, BsRobot, BsListTask, BsNodePlus } from "react-icons/bs";

import Widget from "../widget";
import ManualTaskContent from "../manualtask_content";
import AITaskContent from "../aitask_content";
import ConnectorContent from "../connector_content";

import useMaxPos from "../../../hooks/useMaxPosition";
import { useIDGenerator } from "../../../hooks/useIdGen";

import { updateProject } from "../../../libs/projects";
import { getMicrotask } from "../../../libs/microtasks";


let tempDataC = {
  "title": "Sample ChatGPT Agent title sample ChatGPT Agent title sample ChatGPT Agent title sample ChatGPT Agent title",
  "description": "sample Chat GPT Agent description sample Chat GPT Agent description sample Chat GPT Agent description sample Chat GPT Agent description sample Chat GPT Agent description sample Chat GPT Agent description sample Chat GPT Agent description sample Chat GPT Agent description sample Chat GPT Agent description sample Chat GPT Agent description",                  
  "connector_id" : "id",
  "name": "",
  "input_from" : "",
  "input_to" : "",
  "automatic_status_update_button" : "false",
  "status": "notcomplete",
  "activation_trigger": "",
  "position": 1
}

export default function AddWidget({ setModalOptions=()=>{}, projectJSON={}, templateJSON={}, setTemplateJSON=()=>{}, setProjectJSON=()=>{} }) {
    const microtaskNameInpRef = useRef();
    const addTypeRef = useRef();

    const findMaxPos = useMaxPos();
    const generateUniqueID = useIDGenerator();

    // open preview modal
    const handleChange = (e) => {
      if (e.target.value == 0) {
        document.querySelector(".microtask-name-inp-sec").classList.add("d-none");
      }else if(e.target.value == 1) {
        document.querySelector(".microtask-name-inp-sec").classList.remove("d-none");
      }else if (e.target.value == 2) {
        document.querySelector(".microtask-name-inp-sec").classList.add("d-none");
      }
    }

    const handleClick = () => {
      let addWidgetContent = <>
        <div className="d-flex flex-column gap-3 justify-content-center align-items-start h-100">
          <section className="d-flex gap-1 align-items-center w-100">
            <p>Choose Widget Type:</p>
            <select ref={addTypeRef} onChange={handleChange}>
              <option value={0}>None</option>
              <option value={1}>Microtask</option>
              <option value={2}>Connector</option>
            </select>
            <button className="ms-auto btn btn-primary btn-sm" onClick={handlePreviewClick}>preview</button>
          </section>
          <section className="d-none d-flex align-items-center w-100 microtask-name-inp-sec">
            <input type="text" className="border border-dark p-1 w-100" placeholder="task id" ref={microtaskNameInpRef} />
          </section>
        </div>
      </>
      setModalOptions((state) => ({
        ...state,
        open: true,
        heading: "Add new Task / Connector",
        content: addWidgetContent
      }));
    }

    // add new data to template 
    const handleAddClick = (data) => {
      let templateCopy = {...templateJSON};
      // if data has template that means it's a microtask
      if (!data.template) {
        templateCopy.connectors.connectors.children.push(data);
      }else {
        templateCopy.microtasks.children.push(data);
      }

      // modify the frontend state
      setTemplateJSON(state => state = {...templateCopy});
      // setProjectJSON(state => {
      //   for (let index = 0; index < state.templates.children.length; index++) {
      //     let z = state.templates.children[index];
      //     if (z.template_id==templateCopy.template_id) {
      //       state.templates.children[index] = templateCopy;
      //       return state;
      //     }
      //   }
      // });
      setModalOptions((state) => ({
        heading: "",
        content: "",
        open: false,
        data: null
      }));

      // modify the db
      projectJSON.templates.children.length && updateProject(projectJSON);
    }
  
    const handlePreviewClick = async () => {
      try {
        if (addTypeRef.current.value == 1 && (!microtaskNameInpRef.current.value || microtaskNameInpRef.current.value.length < 3)) {
          return 0;
        }

        // fetch the data
        let data = null, content = "";
        if (addTypeRef.current.value == 0) {
          data = null;
        }else if(addTypeRef.current.value == 1) {
          data = await getMicrotask(microtaskNameInpRef.current.value);
          if (!data) return 0;

          // add additional fields
          data["model"] = "chatgpt";
          data["numberOfExecutedTask"] = 0;
          data["numberOfInput"] = 0;
        }else if (addTypeRef.current.value == 2) {
          data = {...tempDataC};
          if (!data) return 0;
          
          // add additional fields
          data.connector_id += generateUniqueID()+(parseInt(findMaxPos(templateJSON))+1);
        }

        if (!data) return 0;

        // setting the position
        data.position = parseInt(findMaxPos(templateJSON))+1;

        if (data.template && data.template.includes("manual")) {
          content = <ManualTaskContent {...data} preview={true} />;
        }else if (data.template && data.template.includes("chatGPT")) {
          // adding extra fields here
          data.taskInput = "";
          data.taskOutput = "";
          content = <AITaskContent {...data} preview={true} />
        }else {
          content = <ConnectorContent {...data} preview={true} />
        }

        content = (
          <>
            {content}
            <section className="d-flex justify-content-around mt-3 w-100">
              <button className="btn btn-primary" onClick={() => {handleAddClick(data)}}>Add to Template</button>
            </section>
          </>
        )
    
        data &&
        setModalOptions((state) => ({
          open: true,
          heading: data.microtask || "connector",
          content: content,
          data: data
        }));
      }catch (err) {
        console.log(err)
      }
    }
    
  
    return (
      <div className="widget-divs mb-auto" onClick={handleClick}>
        <BsNodePlus />
        <strong>
          Add New Task
        </strong>
      </div>
    );
}