import React, { useState, useEffect } from "react";
import { Button, Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';

import "bootstrap/dist/css/bootstrap.min.css";

import "./style.css";





export default function Sidebar({ collapsed=true, handleCollapse=()=>{} }) {
  const [expandedItems, setExpandedItems] = useState({});
  // const [showAlert, setShowAlert] = useState(false);

  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const menuPermissions = user["user_menu"];

  // const getNavs = () => {
  //   let navs = [];
  //   for(let i=0; i <= menuPermissions.length; i++) {
  //     let z = menuPermissions[i];
  //     if (!z) continue;

  //     //console.log( Object.values(z)[0] )
  //     let nav = Object.values(z)[0];

  //     navs.push({text: nav.text, link: nav.link ?? "/", children: nav.children ?? []});
  //   }

  //   return navs;
  // }
  
  const handleLinkClick = async (linkObj) => {
    const { type, url, headers, parameter } = linkObj;
    let finalUrl = url;
    // Only call Flask API if 'parameter' exists
    if (parameter) {
      try {
        const response = await fetch("https://biotasks.com/api/modify_url", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            parameter: parameter, // Ensure 'parameter' is correctly defined in your context
            email: user.email, // Ensure 'user.email' is correctly defined in your context
            url: url,
          }),
        });
        const data = await response.json();
        if (data.status === "success" && data.url) {
          finalUrl = data.url; // Redirect to the new URL
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    }
    finalUrl = process.env.REACT_APP_ENV == "local" ? `http://localhost:3000${new URL(finalUrl).pathname}` : finalUrl;

    if (type === "POST") {
      // Create a form element
      const form = document.createElement("form");
      form.method = type;
      form.action = finalUrl;

      // Create form fields for each header (assuming they are to be sent as body parameters)
      for (const key in headers) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = headers[key];
        form.appendChild(input);
      }

      // Append form to DOM and submit it
      document.body.appendChild(form);
      form.submit();
    } else if (type === "GET") {
      // Append headers as query parameters (if they are to be sent this way)
      if (headers) {
        const queryParams = new URLSearchParams(headers).toString();
        finalUrl += "?" + queryParams;
      }

      // Redirect
      window.location.href = finalUrl;
    }
  };

  const renderNav = (data, level = 0) => {
    if (!data) return null;

    return data.map((itemObj, index) => {
      const key = Object.keys(itemObj)[0];
      const item = itemObj[key];
      const paddingLeft = `${level * 20}px`; // You can adjust the multiplier as needed

      return (
        <React.Fragment key={index}>
          <div
            style={{
              paddingLeft,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => {
              if (item.text === "Automation Tasks") {
                window.open("/search", "_blank");
              } else if (item.children && item.children.length > 0) {
                const newExpandedItems = { ...expandedItems };
                newExpandedItems[item.text] = !newExpandedItems[item.text];
                setExpandedItems(newExpandedItems);
              } else if (item.link) {
                handleLinkClick(item.link);
              }
            }}
          >
            <Nav.Link
              onClick={(e) => {
                if (item.link) {
                  e.stopPropagation(); // Prevent click from being captured by parent div
                  handleLinkClick(item.link);
                }
              }}
              href="#"
              style={{ display: "flex", alignItems: "center" }}
            >
              {level === 0 ? "" : "• "}
              {item.text}
            </Nav.Link>
            <span style={{ color: "white" }}>
              {item.children && item.children.length > 0 && (
                <div
                  style={{
                    width: "0",
                    height: "0",
                    borderTop: "5px solid transparent",
                    borderBottom: "5px solid transparent",
                    borderRight: expandedItems[item.text]
                      ? "5px solid white"
                      : "none",
                    borderLeft: !expandedItems[item.text]
                      ? "5px solid white"
                      : "none",
                  }}
                ></div>
              )}
            </span>
          </div>

          {item.children &&
            expandedItems[item.text] &&
            renderNav(
              item.children.map((childObj) => {
                const childKey = Object.keys(childObj)[0];
                return { [childKey]: childObj[childKey] };
              }),
              level + 1
            )}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={"d-flex flex-column sidebar "+ (collapsed && "hidden")}>
      {/*<button className="menu-close-btn" onClick={handleCollapse}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
        </svg>
      </button>*/}
      <br />
      <br />
      <br />

      <div className="d-flex flex-column gap-2">
        {/*{
          getNavs().map((z, i) => <a key={i} href={z.link}>{z.text}</a>)
        }*/}
        {
          renderNav(menuPermissions)
        }
      </div>
    </div>
  );
}
