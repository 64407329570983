import React, { useState, useEffect, useRef } from "react";

import { BsPlugin, BsRobot, BsListTask, BsNodePlus } from "react-icons/bs";
import { FaEdit, FaCopy } from "react-icons/fa";

import ManualTaskContent from "./manualtask_content";
import AITaskContent from "./aitask_content";
import ConnectorContent from "./connector_content";

import "./style.css";


export default function Card({ data={}, preview=false, executing=false, view=null, screen=0,
  editTaskJSON=()=>{}, deleteTaskJSON=()=>{}, setModalOptions=()=>{}, runAITask=()=>{}
}) {
  const [changes, setChanges] = useState([]);

  const handleDelClick = async (type, taskid, position) => {
    await deleteTaskJSON(type, taskid, position);
  }
  const handleSaveClick = async (type, taskid, position, callback) => {
    try {
      let temp = [];
      changes.forEach(z => {
        if (z.key == "taskInput") {
          if (z.valueRef.current.value.length) {
            temp.push({ key: z.key, value: z.valueRef.current.value.split("\n") });
          }else {
            temp.push({ key: z.key, value: "" });
          }
        }else if(z.key == "input_from" || z.key == "input_to" || z.key == "activation_trigger") {
          temp.push({ key: z.key, value: z.valueRef.current.value.split(".").join("_") });
        }else if(z.key == "combineLine") {
          temp.push({ key: z.key, value: z.valueRef.current.checked });
        }else {
          temp.push({ key: z.key, value: z.valueRef.current.value });
        }
      });
      await editTaskJSON(type, taskid, position, temp);
      
      // reset
      setChanges([]);

      if (callback) callback();
    }catch(err) {
      console.log(err);
    }
  }

  const handleEditClick = (key, elementRef, disable=true) => {
    try {
      disable && (elementRef.current.disabled = !elementRef.current.disabled);

      // add all the fields keys and values, that needs to updated
      if (!changes.find(z => z.key == key)) {
        let temp = [];
        temp.push({ key: key, valueRef: elementRef });
        setChanges(state => {
          state.push({ key: key, valueRef: elementRef });

          return state;
        });
      }
    }catch(err) {
      console.log(err)
    }
  }

  let content = "";
  if (data.connector_id) {
      content = <ConnectorContent {...data} handleDelClick={handleDelClick} preview={preview} view={view} screen={screen}
      handleSaveClick={handleSaveClick} handleEditClick={handleEditClick} state={executing} />
  }else if (data.template.includes("manual")) {
      content = <ManualTaskContent {...data} handleDelClick={handleDelClick} preview={preview} view={view} screen={screen}
      handleSaveClick={handleSaveClick} handleEditClick={handleEditClick} state={executing} />;
  }else if (data.template.includes("chatGPT")) {
      content = <AITaskContent {...data} handleDelClick={handleDelClick} preview={preview} view={view} state={executing} screen={screen}
      handleSaveClick={handleSaveClick} handleEditClick={handleEditClick} runAITask={runAITask} />
  }

  return (
    <div className={"card-divs "+(executing && "executing")}>
      {content}
    </div>
  );
}