import "./SearchResult.css";
import React from 'react';

export const SearchResult = ({ result, link }) => {
  return (
    <div className="search-result" onClick={(e) => window.open(link, "_blank")}>
      {result}
    </div>
  );
};
