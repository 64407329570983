import React, { useState, useEffect, useRef } from "react";

import { FaEdit, FaCopy } from "react-icons/fa";

import { getMicrotask } from "../../libs/microtasks";
import useCopy from "../../hooks/useCopy";

export default function ConnectorContent({
  connector_id="",
  position="",
  title= "",
  description= "",
  input_from= "",
  input_to= "",
  activation_trigger= "",
  automatic_status_update_button="false",
  executionState=false,
  status="notcomplete",

  view=null,
  screen=0,
  preview=false,
  handleDelClick=()=>{},
  handleSaveClick=()=>{},
  handleEditClick=()=>{}
}) {
  const [inputData, setInputData] = useState({});
  const [outputData, setOutputData] = useState({});

  const positionRef = useRef();
  const titleRef = useRef();
  const desRef = useRef();
  const inpfromRef = useRef();
  const outtoRef = useRef();
  const triggerNameRef = useRef();
  const autoRef = useRef();
  const statusRef = useRef();

  const copyToClipboard = useCopy();

  const handleStatusClick = (e) => {
    document.querySelectorAll(".widget-status-radio").forEach(z => {
      z.checked=false;
    });

    e.target.checked = true;
  }

  const handleInpPreviewClick = () => {
    let temp = {...inputData};
    // add error/null handle
    input_from.split(',').forEach(async z => {
      const res = await getMicrotask(z.trim());
      if (res) {
        temp[z.trim()] = res;
        setInputData(temp);
      }
    });
  }
  const handleOutPreviewClick = () => {
    let temp = {...outputData}
    input_to.split(',').forEach(async z => {
      const res = await getMicrotask(z.trim());
      if (res) {
        temp[z.trim()] = res;
        setOutputData(temp);
      }
    });
  }

  useEffect(() => {
    let temp = {...inputData}, count = 0;
    // add error/null handle
    input_from.split(',').forEach(async z => {
      const res = await getMicrotask(z.trim());
      if (res) {
        temp[z.trim()] = res;
        setInputData(temp);
      }
    });

    temp = {...outputData}
    input_to.split(',').forEach(async z => {
      const res = await getMicrotask(z.trim());
      if (res) {
        temp[z.trim()] = res;
        setOutputData(temp);
      }
    });
    
  }, [input_from, input_to]);
  
  return (
    <div className="d-flex flex-column gap-3 align-items-start h-100 modal-widget-content-div">
      <div className="d-flex align-items-center justify-content-between gap-3 w-100">
          <section className="d-flex gap-1">
            <p>Position:</p>
            <input defaultValue={position} type="number" className="border border-dark" min={1}
              ref={positionRef} onChange={ () => {handleEditClick("position", positionRef, false)} }
              disabled={preview || (screen == 0)}
              />
          </section>
          <section className="d-flex gap-1">
            <p>Task Type:</p>
            <strong>Connector</strong>
          </section>
          {/* <section className="d-flex gap-1">
            <p>MicroTask Number:</p>
            <a href="#">{microtask}</a>
          </section> */}
      </div>

      <section className="w-100">
        <section className="">
          <p className="d-inline">Title:</p>
          {
            (!preview && !executionState) &&
            <>
              <button className="ms-2" onClick={ () => {handleEditClick("title", titleRef)} }>
                <FaEdit />
              </button>
            </>
          }
        </section>
        <input type="text" defaultValue={title} className="w-100 border border-dark"
          disabled ref={titleRef} placeholder="title placeholder" />
      </section>
      <section className="w-100">
        <p className="d-inline">Description:</p>
        {
          (!preview && !executionState) &&
          <button className="ms-2" onClick={ () => {handleEditClick("description", desRef)} }>
            <FaEdit />
          </button>
        }
        <textarea type="text" defaultValue={description} className="w-100 border border-dark" disabled
          ref={desRef}  placeholder="description placeholder"></textarea>
      </section>
      
      {
        view != 2 &&
        <>
        <section className="w-full gap-1">
          <p className="d-inline">Get text input from:</p>
          {
            ( !preview && screen == 1 && !executionState ) &&
            <button className="ms-2" onClick={ () => {handleEditClick("input_from", inpfromRef)} }>
              <FaEdit />
            </button>
          }
          <section className="d-flex align-items-center gap-3">
            <input ref={inpfromRef} type="text" defaultValue={input_from.split(",").map(z => z.trim().split("_").join(".")).join(",")} className="w-100 border border-dark" disabled
            placeholder="Enter MicroTask Number for the text box that input text will be pulled from" />
            <button className="btn btn-secondary" onClick={handleInpPreviewClick} disabled={preview}>Preview</button>
          </section>
        </section>
        <div className="connector-title-des-div">
          {
            input_from.split(",").map((z, i) => {
              return (<div key={Math.random()*999999} className="mb-3" data-id={z.trim()}>
                <strong>{z.trim()}</strong>
                <section className="w-100">
                  <p>Input Microtask Title:</p>
                  <strong className="w-100">{inputData[z.trim()]?.title}</strong>
                  {/* <input type="text" value={title} className="w-100 border border-dark" disabled /> */}
                </section>
                <section className="w-100">
                  <p>Input Microtask Description:</p>
                  <strong className="w-100">{inputData[z.trim()]?.description}</strong>
                  {/* <textarea type="text" value={description} className="w-100 border border-dark" disabled
                    ></textarea> */}
                </section>
              </div>)
            })
          }
        </div>
        

        <section className="w-full gap-1">
          <p className="d-inline">Send text output to:</p>
          {
            ( !preview && screen == 1 && !executionState ) &&
            <button className="ms-2" onClick={ () => {handleEditClick("input_to", outtoRef)} }>
              <FaEdit />
            </button>
          }
          <section className="d-flex align-items-center gap-3">
            <input ref={outtoRef} type="text" defaultValue={input_to.split(",").map(z => z.trim().split("_").join(".")).join(",")} className="w-100 border border-dark" disabled
              placeholder="Enter MicroTask Number for the text box that aggregated response will be sent to" />
            <button className="btn btn-secondary" onClick={handleOutPreviewClick} disabled={preview}>Preview</button>
          </section>
        </section>
        <div className="connector-title-des-div">
          {
            input_to.split(",").map((z, i) => (
              <div key={i*Math.random()} className="mb-3">
                <section className="w-100">
                  <p>Output Microtask Title:</p>
                  <strong className="w-100">{outputData[z.trim()]?.title}</strong>
                  {/* <input type="text" value={title} className="w-100 border border-dark" disabled /> */}
                </section>
                <section className="w-100">
                  <p>Output Microtask Description:</p>
                  <strong className="w-100">{outputData[z.trim()]?.description}</strong>
                  {/* <textarea type="text" value={description} className="w-100 border border-dark" disabled
                    ></textarea> */}
                </section>
              </div>
            ))
          }
        </div>
        </>
      }

      <section className="">
        <p className="d-inline">Microtask Completion Activation Trigger:</p>
        {
          ( !preview && screen == 1 && !executionState ) &&
          <button className="ms-2" onClick={ () => {handleEditClick("activation_trigger", triggerNameRef)} }>
            <FaEdit />
          </button>
        }
        <input type="text" defaultValue={activation_trigger.split("_").join(".")} className="w-100 border border-dark" disabled
          ref={triggerNameRef} placeholder="Enter MicroTask Number for the task that will activate this agent when that task is completed" />
      </section>

      <section className="d-flex align-items-center gap-2">
        <label className="">
          <input type="checkbox" disabled={preview || executionState} ref={autoRef}
            onChange={ () => {autoRef.current.value=autoRef.current.checked; handleEditClick("automatic_status_update_button", autoRef, false)} }
           defaultChecked={automatic_status_update_button == "true" ? true : false} />
        </label>
        <p className="">Automatically change status of output box of output MicroTask to "Completed" when done:</p>
      </section>

      
      {/* <section className="d-flex">
        <p className="">Widget State:</p>
        <label className="d-flex align-items-center text-danger">
          <input type="radio" className="ms-2 widget-status-radio" checked={!state ? true : false} readOnly />
          Inactive
        </label>
        <label className="d-flex align-items-center text-success">
          <input type="radio" className="ms-2 widget-status-radio" checked={state ? true : false} readOnly />
          Active
        </label>
      </section> */}

      {
        !preview &&
        <section className="d-flex justify-content-around mt-3 w-100">
          <button className="btn btn-success" onClick={ ()=> {handleSaveClick("connectors", connector_id, position)} }>Save / Update</button>
          {
            screen == 1 &&
            <button className="btn btn-danger" onClick={ ()=> {handleDelClick("connectors", connector_id, position)} }>Delete</button>
          }
        </section>
      }
    </div>
  );
}
