import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { IoSettingsSharp } from "react-icons/io5";

import { deleteTemplate } from "../../../libs/templates";


function SettingsBtn({ setModalOptions, templateJSON }) {
    const [operations, setOperations] = useState([]); //1: status update, 2: load template, 3: save template

    const statusRef = useRef();
    const saveNameInpRef = useRef();

    const navigate = useHistory();

    // const addOperation = (id=null) => {
    //     if ( !id || operations.includes(id) ) {return 0;}

    //     setOperations(state => {
    //         state.push(id)
    //         return state;
    //     });
    // }

    // const handleSaveClick = () => {
    //     //console.log(operations)
    //     for (let index = 0; index < operations.length; index++) {
    //         let operation = operations[index];

    //         if (operation == 1) {
    //             updateTemplate({...templateJSON, status: statusRef.current.checked}).then(data => {
    //                 if (data.error) {
    //                     alert("error!");
    //                 }else {
    //                     alert("updated!");
    //                 }
    //             });
    //         }else if (operation == 2) {
    //             console.log(operation)
    //         }else if (operation == 3) {
    //             delete templateJSON["_id"];
    //             createTemplate({...templateJSON, template: saveNameInpRef.current.value, createdAt: new Date()}).then(data => {
    //                 if (data.error) {
    //                     alert("error!");
    //                 }else {
    //                     alert("created");
    //                 }
    //             });

    //             //reset the elements
    //             saveNameInpRef.current.value = "";
    //         }
    //     }

    //     // reset states
    //     setOperations(state => {state.length = 0; return state;});
    // }

    const handleDeleteClick = () => {
        deleteTemplate(templateJSON.template_id).then(async data => {
            if (!data || data.error) {
                alert("error!");
            }else {
                alert("deleted!");
                navigate.push("/projects");
            }
        });
    }

    const handleSettingsClick = () => {
        let content = (
            <div className="d-flex flex-column gap-3 align-items-start h-100">
                {/* <section className="d-flex align-items-center">
                    <input type="checkbox" className="project-modal-inputs" defaultChecked={templateJSON.status}
                        onChange={ () => {addOperation(1)} } ref={statusRef} />
                    <label className="ms-2">
                        Setup complete
                    </label>
                </section>
                <section>
                    <label>
                        Load template
                    </label>
                    <select className="ms-2" onChange={ () => {addOperation(2)} }>
                        <option>template1</option>
                        <option>template2</option>
                        <option>template3</option>
                    </select>
                </section> */}
                {/* <section className="d-flex align-items-center w-100">
                    <label className="">
                        Save as template
                    </label>
                    <input type="text" placeholder="template name" onChange={ () => {addOperation(3)} }
                        className="w-50 ms-2 border-bottom border-dark project-modal-inputs" ref={saveNameInpRef}
                    />
                </section> */}
                <section className="d-flex justify-content-around mt-5 w-100">
                    {/* <button className="btn btn-primary" onClick={handleSaveClick}>Save / Load</button> */}
                    <button className="btn btn-danger" onClick={handleDeleteClick}>Delete</button>
                </section>
            </div>
        );
        setOperations(state => {state.length = 0; return state;});
        setModalOptions({open: true, heading: "settings", content: content, data: null});
    }

    return (
        <button onClick={handleSettingsClick}>
            <IoSettingsSharp />
        </button>
    );
}

export default function TemplateNavs({ screen=0, setScreen=()=>{}, setModalOptions=()=>{}, templateJSON=null, setView=()=>{} }) {
    const InfoSwitchClick = (e) => {
        document.querySelectorAll(".active-template-info-switch").forEach(z => z.classList.remove("active-template-info-switch"));
        e.target.classList.add("active-template-info-switch");
    }

    const handleAllProjectsClick = (e) => {
        if (screen === 0) return 0;

        InfoSwitchClick(e);
        setScreen(0);
    }

    const handleWidgetsClick = (e) => {
        if (screen === 1) return 0;

        InfoSwitchClick(e);
        setScreen(1);
    }
    const handleViewChange = (e) => {
        setView(e.target.value);
    }
    
    return(
        <div className="d-flex justify-content-between align-items-center gap-3 template-info-switch-div">
            <section className="d-flex gap-3">
                <button className="active-template-info-switch">See All Project Data</button>
                {/* <span>|</span>
                <button onClick={handleWidgetsClick}>See Connector/Widget</button>
                <span>|</span>
                <button disabled={true} onClick={InfoSwitchClick}>See Wireframe</button> */}
            </section>
            <section className="ms-auto">
                <label>
                    view:
                </label>
                <select className="ms-2 border-0 border-bottom border-dark" onChange={ handleViewChange }>
                    <option value={1}>view 1</option>
                    <option value={2}>view 2</option>
                    <option value={3}>view 3</option>
                </select>
            </section>
            <section>
                <SettingsBtn setModalOptions={setModalOptions} templateJSON={templateJSON} />
            </section>
        </div>
    );
}