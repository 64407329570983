import { useState, useEffect } from 'react';

function convertKeysToCamelCase(arr) {
    if (!arr || arr.length <= 0) return [];

    return arr.map(obj => {
        const newObj = {};
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                const camelKey = key.split(" ").join("");
                newObj[camelKey] = obj[key];
            }
        }
        return newObj;
    });
}

const useTaskParser = () => {
    return (data=[]) => {
        if (!data || data.length <= 1) return [];
        
        const tasks = data.microtasks.children;
        const connectors = convertKeysToCamelCase(
            data.connectors.connectors.children
        );

        // Combine microtasks and connectors
        const combined = tasks.concat(connectors);

        // Sort combined array based on positions
        const sorted = combined.sort((a, b) => {
            return parseInt(a.position) - parseInt(b.position);
        });

        return sorted;
    }
};

export default useTaskParser;
