import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { IoSettingsSharp } from "react-icons/io5";
import { BsFillGridFill, BsViewList } from "react-icons/bs";

import { createTemplate, updateTemplate, deleteTemplate, getTemplate } from "../../../libs/templates";
import { updateProject } from "../../../libs/projects";

import { useIDGenerator } from "../../../hooks/useIdGen";

// function SettingsBtn({
//     setModalOptions, setProjectJSON={setProjectJSON}, setTemplateJSON={setTemplateJSON},
//     projectJSON, templateJSON, templateList=[]
// }) {
//     const [operations, setOperations] = useState([]); //1: status update, 2: load template, 3: save template

//     const statusRef = useRef();
//     const saveNameInpRef = useRef();
//     const loadTemplateRef = useRef();

//     const navigate = useHistory();

//     const genId = useIDGenerator();

//     const addOperation = (id=null) => {
//         if ( !id || operations.includes(id) ) {return 0;}

//         setOperations(state => {
//             state.push(id)
//             return state;
//         });
//     }

//     const handleSaveClick = () => {
//         try {
//             let copyProjectData = {...projectJSON}, copyTemplateData = {...templateJSON};
//             for (let index = 0; index < operations.length; index++) {
//                 let operation = operations[index];

//                 if (operation === 1) { //updating status
//                     copyProjectData = {...copyProjectData, status: statusRef.current.checked};
//                     updateProject(copyProjectData).then(data => {
//                         if (data.error) {
//                             copyProjectData = null;
//                             throw "error!";
//                         }
//                     });

//                     // status is project level key, so no need to update the template
//                     copyProjectData && setProjectJSON(copyProjectData);
//                 }else if (operation === 2) { // loading a new template in project
//                     getTemplate(loadTemplateRef.current.value).then(async data => {
//                         if (data.error) {
//                             copyProjectData = null;
//                             throw "error!";
//                         }else {
//                             delete data["_id"];
//                             copyProjectData.templates.children.push(data);
//                             copyTemplateData = {...data};

//                             let projectData = await updateProject(copyProjectData);
//                             if (projectData.error) {
//                                 copyProjectData = null;
//                                 copyTemplateData = null;
//                                 throw "error!";
//                             }

//                             copyProjectData && setProjectJSON(copyProjectData);
//                             copyTemplateData && setTemplateJSON(copyTemplateData);
//                         }
//                     });
//                 }else if (operation === 3) { // saving/create a new template
//                     createTemplate(genId(), {...copyTemplateData, name: saveNameInpRef.current.value}).then(data => {
//                         if (data.error) {
//                             throw "error!";
//                         }else {
//                             navigate.go(0)
//                         }
//                     });
//                 }
//             }
//         }catch (err) {
//             alert(err);
//         }finally {
//             // reset states & elements
//             saveNameInpRef.current.value = "";
//             setOperations(state => {state.length = 0; return state;});
//             setModalOptions(state => ({open: false, heading: "", content: ""}));
            
//             alert("updated!");
//         }
//     }

//     const handleDeleteClick = async (e) => {
//         e.currentTarget.disabled = true;

//         let copyProjectData = {...projectJSON};
        
//         for (let index = 0; index < copyProjectData.templates.children.length; index++) {
//             const element = copyProjectData.templates.children[index];
            
//             if(element.template_id == templateJSON.template_id) {
//                 copyProjectData.templates.children.splice(index, 1);
//                 break;
//             }
//         }

//         let data = await updateProject(copyProjectData);
//         if (data.error) {
//             copyProjectData = null;
//             alert("error!");
//             return 0;
//         }
//         navigate.push(".");

//         setModalOptions(state => ({open: false, heading: "", content: ""}));
//     }

//     const handleSettingsClick = () => {
//         let content = (
//             <div className="d-flex flex-column gap-3 align-items-start h-100">
//                 <section className="d-flex align-items-center">
//                     <input type="checkbox" className="project-modal-inputs" defaultChecked={projectJSON.status}
//                         onChange={ () => {addOperation(1)} } ref={statusRef} />
//                     <label className="ms-2">
//                         Setup complete
//                     </label>
//                 </section>
//                 <section>
//                     <label>
//                         Load template
//                     </label>
//                     <select className="ms-2" onChange={ () => {loadTemplateRef.current.value != "none" && addOperation(2)} } ref={loadTemplateRef}>
//                         <option value={null}>none</option>
//                         {
//                             templateList.map((z, i) => <option key={i} value={z.id}>{z.name}</option>)
//                         }
//                     </select>
//                 </section>
//                 <section className="d-flex align-items-center w-100">
//                     <label className="">
//                         Save as template
//                     </label>
//                     <input type="text" placeholder="template name" onChange={ () => {addOperation(3)} }
//                         className="w-50 ms-2 border-bottom border-dark project-modal-inputs" ref={saveNameInpRef}
//                     />
//                 </section>
//                 <section className="d-flex justify-content-around mt-5 w-100">
//                     <button className="btn btn-primary" onClick={handleSaveClick}>Save / Load</button>
//                     <button className="btn btn-danger" onClick={handleDeleteClick}>Remove Template</button>
//                 </section>
//             </div>
//         );
//         setOperations(state => {state.length = 0; return state;});
//         setModalOptions({open: true, heading: "settings", content: content});
//     }

//     return (
//         <button onClick={handleSettingsClick}><IoSettingsSharp /></button>
//     );
// }

export default function ProjectNavs({ screen=0, setScreen=()=>{}, setModalOptions=()=>{}, setView=()=>{},
    setTemplateJSON={setTemplateJSON}, setProjectJSON={setProjectJSON},
    projectJSON=null, templateJSON=null, templateList=[] }) {
    
    const statusRef = useRef();
    const saveNameInpRef = useRef();
    const loadTemplateRef = useRef();

    const navigate = useHistory();

    const genId = useIDGenerator();

    const InfoSwitchClick = (e) => {
        setModalOptions(state => ({open: false, heading: "", content: "", data: null}));

        const elements = document.querySelectorAll(".active-project-info-switch");
        for (const element of elements) {
            element.classList.remove("active-project-info-switch");
            element.classList.add("project-info-switch");
        }
        e.target.classList.add("active-project-info-switch");
    }
    const handleAllProjectsClick = (e) => {
        if (screen === 0) return 0;

        InfoSwitchClick(e);
        setScreen(0);
    }
    const handleWidgetsClick = (e) => {
        if (screen === 1) return 0;

        InfoSwitchClick(e);
        setScreen(1);
    }

    const handleViewChange = (e) => {
        setModalOptions(state => ({open: false, heading: "", content: "", data: null}));
        setView(e.target.value);
    }
    const handleTemplateChange = (e) => {
        setModalOptions(state => ({open: false, heading: "", content: "", data: null}));
        let newActiveTemplate = projectJSON.templates.children.find(z => z.template_id == e.currentTarget.value);
        setTemplateJSON(newActiveTemplate);
    }


    const handleStatusChange = () => {
        try {
            let copyProjectData = {...projectJSON}, copyTemplateData = {...templateJSON};
            copyProjectData = {...copyProjectData, status: statusRef.current.checked};
            updateProject(copyProjectData).then(data => {
                if (data.error) {
                    copyProjectData = null;
                    throw data.error;
                }else {
                    alert("updated!")
                }
            });
        }catch(err) {
            console.log(err);
        }
    }

    const handleDelClick = async (e) => {
        e.currentTarget.disabled = true;

        let confirmation = window.confirm("are you sure you want to delete this template from the project?");
        if (!confirmation) {
            e.currentTarget.disabled = false;
            return 0;
        }

        let copyProjectData = {...projectJSON};
        
        for (let index = 0; index < copyProjectData.templates.children.length; index++) {
            const element = copyProjectData.templates.children[index];
            
            if(element.template_id == templateJSON.template_id) {
                copyProjectData.templates.children.splice(index, 1);
                break;
            }
        }

        let data = await updateProject(copyProjectData);
        if (data.error) {
            copyProjectData = null;
            alert("error!");
            return 0;
        }
        navigate.go(0);

       // setModalOptions(state => ({open: false, heading: "", content: ""}));
    }

    const handleLoadClick = (e) => {
        e.currentTarget.disabled = true;
        try {
            let copyProjectData = {...projectJSON}, copyTemplateData = {...templateJSON};
            getTemplate(loadTemplateRef.current.value).then(async data => {
                if (data.error) {
                    copyProjectData = null;
                    throw "error!";
                }else {
                    delete data["_id"];
                    copyProjectData.templates.children.push(data);
                    copyTemplateData = {...data};

                    let projectData = await updateProject(copyProjectData);
                    if (projectData.error) {
                        copyProjectData = null;
                        copyTemplateData = null;
                        throw "error!";
                    }

                    copyProjectData && setProjectJSON(copyProjectData);
                    copyTemplateData && setTemplateJSON(copyTemplateData);
                    navigate.go(0);
                }
            });
        }catch(err) {
            console.log(err);
        }
    }

    const handleSaveClick = async (e) => {
        e.currentTarget.disabled = true;
        try {
            let copyProjectData = {...projectJSON}, copyTemplateData = {...templateJSON};

            let defaultTemp = copyProjectData.templates.children.find(z => z.template_id == "defaulttemplate");
            if (defaultTemp) {
                // deleting the default template
                for (let index = 0; index < copyProjectData.templates.children.length; index++) {
                    const element = copyProjectData.templates.children[index];

                    if(element.template_id == "defaulttemplate") {
                        copyProjectData.templates.children.splice(index, 1);
                        break;
                    }
                }

                let data = await updateProject(copyProjectData);
                if (data.error) {
                    throw ("error!");
                }
            }

            // saving the template
            let newTemplate = {...copyTemplateData, name: saveNameInpRef.current.value};
            createTemplate(genId(), newTemplate).then(data => {
                if (data.error) {
                    throw "error!";
                }
            });

            // loading the template
            delete newTemplate["_id"];
            copyProjectData.templates.children.push(newTemplate);
            copyTemplateData = {...newTemplate};

            let updatedProjectData = await updateProject(copyProjectData);
            if (updatedProjectData.error) {
                throw "error!";
            }

            // copyProjectData && setProjectJSON(copyProjectData);
            // copyTemplateData && setTemplateJSON(copyTemplateData);
            navigate.go(0);
        }catch (err) {
            console.log(err);
        }
    }
    
    return(
        <div className="d-flex justify-content-between align-items-end gap-3 project-info-switch-div">
            <section className="d-flex gap-3">
                <button className="active-project-info-switch" onClick={handleAllProjectsClick}>See All Project Data</button>
                <span>|</span>
                <button className="project-info-switch" onClick={handleWidgetsClick}>See Connector/Widget</button>
                <span>|</span>
                <button disabled={true}  onClick={InfoSwitchClick}>See Wireframe</button>
            </section>

            <div className="d-flex flex-column align-items-end gap-3">
                
                <section className="">
                    Switch Template:
                    <select className="ms-2 border-0 border-bottom border-dark" onChange={ handleTemplateChange } defaultValue={templateJSON.template_id}>
                        {
                            projectJSON.templates.children.map((z, i) => <option key={i} value={z.template_id}>{z.name}</option>)
                        }
                    </select>
                </section>
                <section className="">
                    <select className="ms-2 border-0 border-bottom border-dark" onChange={ handleViewChange }>
                        <option value={1}>view 1</option>
                        <option value={2}>view 2</option>
                        <option value={3}>view 3</option>
                    </select>
                </section>

            </div>
            
            <div className="d-flex flex-column align-items-end gap-3">
                <section className="d-flex align-items-center">
                    <label className="ms-2">
                        Setup complete
                    </label>
                    <input type="checkbox" className="project-modal-inputs" defaultChecked={projectJSON.status} ref={statusRef} onChange={handleStatusChange} />
                    <button className="btn btn-danger ms-3" onClick={handleDelClick}>Delete Template</button>
                </section>
                <section className="d-flex align-items-center">
                    <label>
                        Load template
                    </label>
                    <select className="ms-2" ref={loadTemplateRef}>
                        <option value={null}>none</option>
                        {
                            templateList.map((z, i) => <option key={i} value={z.id}>{z.name}</option>)
                        }
                    </select>
                    <button className="btn btn-primary ms-3" onClick={handleLoadClick}>load</button>
                </section>
                <section className="d-flex align-items-center">
                    <label>
                        Save template
                    </label>
                    <input type="text" placeholder="template name" className="" ref={saveNameInpRef} />
                    <button className="btn btn-primary ms-3" onClick={handleSaveClick}>save</button>
                </section>
            </div>
            {/* <section>
                <SettingsBtn setModalOptions={setModalOptions} setProjectJSON={setProjectJSON} setTemplateJSON={setTemplateJSON}
                    templateList={templateList} projectJSON={projectJSON} templateJSON={templateJSON} />
            </section> */}
        </div>
    );
}