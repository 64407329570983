import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

function SignUpPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [username, setUsername] = useState('');
    const history = useHistory();
    
    const handleSignUp = () => {
        if (!email || !password || !username) {
            let missingFields = "";
            if (!email) missingFields += "Email ";
            if (!password) missingFields += "Password ";
            if (!username) missingFields += "Username ";
            alert(`Missing required field(s): ${missingFields}`);
            return;
        }
    
        const payload = {
            email: email,
            password: password,
            contact_number: contactNumber,  // Optional
            username: username
        };
    
    
        fetch("https://biotasks.com/api/user_signup", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === "success") {
                alert("Successfully signed up");
                history.push('/login');  // Redirect to /login
                window.location.reload();
            } else {
                alert("Could not sign up");
            }
        })
        .catch(error => {
            console.error("There was a problem with the signup request", error);
        });
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="card p-4 shadow-lg border-0" style={{ width: '20rem' }}>
                <div className="pt-4 pb-4">
                    <h2 className="text-center mb-4">Sign Up</h2>
                    <div className="mb-3">
                        <input 
                            type="text" 
                            className="form-control"
                            placeholder="Username"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input 
                            type="email" 
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input 
                            type="password" 
                            className="form-control"
                            placeholder="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input 
                            type="text" 
                            className="form-control"
                            placeholder="Contact Number"
                            value={contactNumber}
                            onChange={e => setContactNumber(e.target.value)}
                        />
                    </div>
                    
                    <button className="btn btn-dark w-100" onClick={handleSignUp}>Sign Up</button>
                </div>
            </div>
        </div>
    );
}

export default SignUpPage;
