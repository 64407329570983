import React, { useState, useEffect } from "react";
import { Button, Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';

import "bootstrap/dist/css/bootstrap.min.css";

import Layout from "../layout";

import "./HomePage.css";
//import "../TopBar.css";
// import userData from '../navData.json';


function HomePage() {
  return (
    <Layout heading="Biotasks Homepage">
      <div className="">
        Home Page
      </div>
    </Layout>
  );
}
export default HomePage;
