import React, { useState, useEffect }  from 'react';

import { getAllTemplates, getTemplate } from "../../libs/templates";
import { getAllProjects, getProject } from "../../libs/projects";



function TableRow({ id, type, name, created_at= new Date(), status }) {
    return (
        <tr>
            <td>{type}</td>
            <td>
                <a href={ (type == "Template" ? "/templates/" : "/projects/") + id }>
                    {name}
                </a>
            </td>
            <td>{new Date(created_at).toLocaleString()}</td>
            <td>
                {
                    status === true
                    ?
                    "Complete"
                    :
                    status === false
                    ?
                    "Not Complete"
                    :
                    ""
                }
            </td>
            {/* <td>
                <input type="checkbox" />
            </td> */}
        </tr>
    );
}
export default function ProjectsTable({ activeProjectTypes={}, activeProjectStatus={} }) {
    const [templatesTableData, setTemplatesTableData] = useState([]);
    const [projectsTableData, setProjectsTableData] = useState([]);

    useEffect(() => {
        getAllTemplates().then(async data => {
            let arr = [];
            for (let index = 0; index < data.length; index++) {
                let z = data[index];
                arr.push({...z, type: "Template" });
            }
            setTemplatesTableData(arr);
        });
        getAllProjects().then(async data => {
            let arr = [];
            for (let index = 0; index < data.length; index++) {
                let z = data[index];
                //let tempData = await getProject(z);
                arr.push({...z, type: "Project" });
            }

            setProjectsTableData(arr);
        });
    }, []);

    //console.log(tableData)

    return (
        <table className="w-100 ">
            <thead>
                <tr>
                    <th>TYPE</th>
                    <th>NAME</th>
                    <th>CREATED</th>
                    <th>STATUS</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    templatesTableData.map((z, i) => {
                        if (activeProjectTypes[z.type]) return <TableRow key={i} {...z} />
                    })
                }
                {
                    projectsTableData.map((z, i) => {
                        if (activeProjectTypes[z.type] && activeProjectStatus[z.status ? "1" : "0"]) return <TableRow key={i} {...z} />
                    })
                }
            </tbody>
        </table>
    );
}
