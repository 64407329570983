const endpointBase = process.env.REACT_APP_HOST;
let defaultTemplate = {
    "template_id": "template",
    "name": "template",
    "created_at": new Date(),
    "microtasks": {
        "children": []
    },
    "connectors" : {
        "connectors" : {
            "children": []
        }
    }
};

const createTemplate = async (id=null, data=defaultTemplate) => {
    let returnJson = null;
    try {
        if (!id || !data) return 0;

        delete data["_id"];
        data.template_id = id;
        //data.name += id;
        data.created_at = new Date();

        let res = await fetch(endpointBase+"/api/create_template", {
            method: "POST",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify(data)
        });
        returnJson = await res.json();
    }catch(err) {
        console.log(err);
    }finally {
        return returnJson;
    }
};
const getAllTemplates = async () => {
    let returnJson = null;
    try {
        let res = await fetch(endpointBase+"/api/list_all_templates");
        returnJson = await res.json();
    }catch(err) {
        console.log(err);
        returnJson = [];
    }finally {
        return returnJson;
    }
};
const getTemplate = async (id = null) => {
    let returnJson = null;
    try {
        if (!id) throw "no name";

        let res = await fetch(endpointBase+"/api/pull_template/"+id);
        returnJson = await res.json();
    }catch(err) {
        console.log(err);
        returnJson = defaultTemplate;
    }finally {
        return returnJson;
    }
};
const updateTemplate = async (data=null) => {
    let returnJson = null;
    try {
        if (!data) return 0;

        let res = await fetch(endpointBase+"/api/modify_template", {
            method: "POST",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify(data)
        });
        returnJson = await res.json();
    }catch(err) {
        console.log(err);
    }finally {
        return returnJson;
    }
};
const deleteTemplate = async (templateName = null) => {
    let returnJson = null;
    try {
        if (!templateName) return 0;

        let res = await fetch(endpointBase+"/api/delete_template/"+templateName);
        returnJson = await res.json();
        console.log(returnJson, templateName)
    }catch(err) {
        console.log(err);
    }finally {
        return returnJson;
    }
};

export { createTemplate, getAllTemplates, getTemplate, updateTemplate, deleteTemplate };