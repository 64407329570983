import React, { useState, useEffect, useRef } from "react";
import { FaEdit, FaCopy } from "react-icons/fa";

import useCopy from "../../hooks/useCopy";

export default function ManualTaskContent({
  macrotask= "",
  microtask= "",
  description= "",
  template= "",
  title= "",
  prompt= "",
  response= "",
  model= "",
  instructions= "",
  input= "",
  output= "",
  instructionsFilenames= "",
  inputFilenames= "",
  outputFilenames= "",
  position="",
  executionState=false,
  status="notcomplete",

  view=null,
  screen=0,
  preview=false,
  updateTemplateJSON=()=>{},
  handleDelClick=()=>{},
  handleSaveClick=()=>{},
  handleEditClick=()=>{}
}) {
  const positionRef = useRef();
  const titleRef = useRef();
  const desRef = useRef();

  const mtinpRef = useRef();
  const mtoutRef = useRef();

  const mtinsRef = useRef();

  const statusRef = useRef();

  const [inputLines, setInputLines] = useState(input.length ? input.split("\n").length : 0);
  const [outputLines, setOutputLines] = useState(output.length ? output.split("\n").length : 0);

  const handleInputChange = (e) => {
    let tempInp = e.target.value;
    setInputLines(tempInp.length ? tempInp.split("\n").length : 0);
  }
  const handleOutputChange = (e) => {
    let tempOut = e.target.value;
    setOutputLines(tempOut.length ? tempOut.split("\n").length : 0);
  }

  const copyToClipboard = useCopy();

  const handleStatusClick = (e) => {
    document.querySelectorAll(".widget-status-radio").forEach(z => {
      z.checked=false;
    });

    e.target.checked = true;
  }


  return (
    <div className="d-flex flex-column gap-3 align-items-start h-100">
      <div className="d-flex align-items-center gap-3 justify-content-between w-100">
        <section className="d-flex gap-1">
          <p>Position:</p>
          <input defaultValue={position} type="number" className="border border-dark" min={1}
            ref={positionRef} onChange={ () => {handleEditClick("position", positionRef, false)} }
            disabled={preview || screen == 0}
            />
        </section>
        <section className="d-flex gap-1">
          <p>Task Type:</p>
          <strong>Manual Task</strong>
        </section>
        <section className="d-flex gap-1">
          <p>MicroTask Number:</p>
          <a href="#">{microtask}</a>
        </section>
      </div>
      
      <section className="w-100">
        <section className="">
          <p className="d-inline">MicroTask Title:</p>
          {
            (screen == 1) &&
            <>
              <button className="ms-2" onClick={ () => {handleEditClick("title", titleRef)} }>
                <FaEdit />
              </button>
            </>
          }
        </section>
        <input type="text" defaultValue={title} className="w-100 border border-dark" disabled ref={titleRef} />
      </section>
      <section className="w-100">
        <section className="">
          <p className="d-inline">MicroTask Description:</p>
          {
            (screen == 1) &&
            <>
              <button className="ms-2" onClick={ () => {handleEditClick("description", desRef)} }>
                <FaEdit />
              </button>
            </>
          }
        </section>
        <textarea type="text" defaultValue={description} className="w-100 border border-dark" disabled ref={desRef}></textarea>
      </section>

      {
        view != 2 &&
        <>
          <section className="w-100">
            <p className="d-inline">MicroTask Instruction:</p>
            {
              (!preview && (screen == 1) && !executionState) && 
              <button className="ms-2" onClick={ () => {handleEditClick("instructions", mtinsRef)} }>
                <FaEdit />
              </button>
            }
            <textarea type="text" defaultValue={instructions} className="w-100 border border-dark" disabled
              ref={mtinsRef}></textarea>
          </section>
          <section className="w-100">
            <p className="d-inline">Resource Files:</p>
            <ul className="w-100 overflow-auto">
              {
                instructionsFilenames.map((z, i) => <li key={i}><a href={"http://localhost:5001/api/download_file/"+z} target="_blank">{z.slice(9)}</a></li>)
              }
            </ul>
            <button className="ms-2 btn btn-secondary" disabled={preview || executionState}>
              Upload File
            </button>
          </section>

          <section className="w-100">
            <section className="w-100 d-flex justify-content-between">
              <section>
                <p className="d-inline">MicroTask Input:</p>
                {
                  (!preview && (screen == 0) && !executionState) && 
                  <>
                    <button className="ms-2" onClick={ () => {handleEditClick("input", mtinpRef)} }>
                      <FaEdit />
                    </button>
                    <button className="ms-2" onClick={ () => {copyToClipboard(mtinpRef.current.value)} }>
                      <FaCopy />
                    </button>
                  </>
                }
              </section>
              <code>{inputLines} lines</code>
            </section>
            <textarea type="text" className="w-100 border border-dark" defaultValue={input} disabled ref={mtinpRef}
              onChange={handleInputChange}></textarea>
          </section>
          <section className="w-100">
            <p className="d-inline">Resource Files:</p>
            <ul className="w-100 overflow-auto">
              {
                inputFilenames.map((z, i) => <li key={i}><a href={"http://localhost:5001/api/download_file/"+z} target="_blank">{z.slice(9)}</a></li>)
              }
            </ul>
            <button className="ms-2 btn btn-secondary" disabled={preview || executionState}>
              Upload File
            </button>
          </section>

          <section className="w-100">
            <section className="w-100 d-flex justify-content-between">
              <section>
                <p className="d-inline">MicroTask Output:</p>
                {
                  ( !preview && screen == 0 && !executionState ) && 
                  <>
                    <button className="ms-2" onClick={ () => {handleEditClick("output", mtoutRef)} }>
                      <FaEdit />
                    </button>
                    <button className="ms-2" onClick={ () => {copyToClipboard(mtoutRef.current.value)} }>
                      <FaCopy />
                    </button>
                  </>
                }
              </section>
              <code>{outputLines} lines</code>
            </section>
            <textarea type="text" className="w-100 border border-dark" defaultValue={output} disabled ref={mtoutRef}
               onChange={handleOutputChange} ></textarea>
          </section>
          <section className="w-100">
            <p className="d-inline">Submitted Task Files:</p>
            <ul>
              {
                outputFilenames.map((z, i) => <li key={i}><a href={"http://localhost:5001/api/download_file/"+z}>{z.slice(9)}</a></li>)
              }
            </ul>
            <button className="ms-2 btn btn-secondary" disabled={preview || executionState}>
              Upload File
            </button>
          </section>
        </>
      }

      <div className="d-flex justify-content-around w-100">
        <section className="d-flex">
          <p className="">Widget State:</p>
          <label className="d-flex align-items-center text-danger">
            <input type="radio" className="ms-2 widget-status-radio" checked={!executionState ? true : false} readOnly />
            Inactive
          </label>
          <label className="d-flex align-items-center text-success">
            <input type="radio" className="ms-2 widget-status-radio" checked={executionState ? true : false} readOnly />
            Active
          </label>
          {/* <select className="ms-2" defaultValue={state} ref={statusRef} disabled={preview}>
            <option value={"false"}>Inactive</option>
            <option value={"true"}>Active</option>
          </select> */}
        </section>
        <section className="d-flex">
          <p className="">Widget Status:</p>
          {/* <label className="d-flex align-items-center ">
            <input type="radio" className="ms-2 widget-status-radio" onClick={handleStatusClick} />
            Not Completed
          </label>
          <label className="d-flex align-items-center ">
            <input type="radio" className="ms-2 widget-status-radio" onClick={handleStatusClick} />
            Completed
          </label> */}
          <select className="ms-2" defaultValue={status} ref={statusRef} disabled={preview || executionState}
            onChange={ () => {handleEditClick("status", statusRef, false)} }>
            <option value={"notcomplete"}>Not Complete</option>
            <option value={"complete"}>Complete</option>
          </select>
        </section>
      </div>
      
      {
        !preview &&
        <section className="d-flex justify-content-around mt-3 w-100">
          <button className="btn btn-success" onClick={ ()=>{handleSaveClick("microtasks", microtask, position)} }>Save / Update</button>
          {
            screen == 1 &&
            <button className="btn btn-danger" onClick={ ()=> {handleDelClick("microtasks", microtask, position)} }>Delete</button>
          }
        </section>
      }
    </div>
  );
}