const endpointBase = process.env.REACT_APP_HOST;
/*

{
    "project_id": "project1",
    "template_id": "template2",
    "microtask": "1_0_1_4_1",
    "input": ['input1','input2']
}

*/ 

const executePipeline = async (data=null) => {
    let returnJson = null;
    try {
        if (!data) return 0;

        let res = await fetch(endpointBase+"/api/execute_pipeline_task", {
            method: "POST",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify(data)
        });
        returnJson = await res.json();
    }catch(err) {
        console.log(err);
    }finally {
        return returnJson;
    }
};



export { executePipeline };